import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Modal,
  ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { I18n, $ } from '../../utils';
import request from '../order_item/request';
import FieldInput from './field_input';
import InvalidInfo from './invalid_info';
import ViewableData from './viewable_data';
import Select from 'components/select';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import 'flatpickr/dist/l10n/fi.js';

class OrderRequestApp extends React.Component {
  state = this.props.orderRequestProps

  onDrop = files => {
    const maxSizeJpeg = 2097152; // 2Mb
    const maxSizePng = 10485760; // 10Mb
    const file = files[0];
    if (
      (file.type === 'image/jpeg' && file.size < maxSizeJpeg) ||
      (file.type === 'image/png' && file.size < maxSizePng)
    ) {
      this.setState({ readyToCrop: true, photoUrl: files[0].preview, cropperModal: true });
    } else {
      $.taikala.flash(I18n.t('photo_upload.errors.size_validator'), 'error');
    }
  };

  upload = () => {
    const url = window.location.pathname.replace('edit', '');
    request
      .put(url, {
        order_request: {
          fixed_fields: this.state.fixedFieldsValues,
          extra_fields: this.state.extraFieldsValues,
          photo: this.state.croppedPhoto
        }
      })
      .then(resp => {
        if (resp.statusCode === 200) {
          window.location.href = url;
        } else {
          const jsonBody = JSON.parse(resp.body);
          if (jsonBody.invalids) {
            this.setState({
              unfulfilled: jsonBody.invalids
            });
          } else {
            $.taikala.flash(I18n.t('customer.action.order_request.error_info') +
            JSON.parse(resp.body).error, 'error');
          }
        }
      });
  }

  handleChange = (e, fixedField) => {
    const name = e.target.name;
    const value = e.target.value;
    const params = fixedField
      ? this.state.fixedFieldsValues
      : this.state.extraFieldsValues;
    this.updateChanges(name, value, params);
  };
  
  handleSelectChange = (value, fieldId) => {
    this.updateChanges(fieldId, value.value, this.state.extraFieldsValues);
  }

  updateChanges = (name, value, params) => {
    params[name] = value;
    this.setState({ params });
  };

  acceptPhoto = () => {
    this.setState({
      croppedPhoto: this.refs.cropper.getCroppedCanvas().toDataURL(),
      cropperModal: false,
      photoAlreadyEdited: true,
      handled: true
    });
  }
  
  closeCropperModal = () => {
    this.setState({
      cropperModal: false,
      photoUrl: this.props.orderRequestProps.photoUrl
    });
  }
  
  createSelectOptions = (field) => {
    return field.options.split(';').map(value => { return { value: value, label: value }; });
  }

  yearOptions = () => {
    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 50;
    const options = [];
    for (let i = currentYear; i < maxYear; i++) {
      let value = i.toString();
      options.push({ value, label: value });
    }
    return options;
  }
  
  backToOrderItemList = () => {
    window.location.href = this.props.orderRequestsUrl;
  }
  
  imgStyles (height, width) {
    let maxHeight = height;
    let maxWidth = width;
    let { x, y } = this.props.aspectRatio;

    return ({
      maxHeight: maxHeight + 'px',
      maxWidth: maxWidth + 'px',
      width: (x / y) * maxHeight + 'px',
      height: (y / x) * maxWidth + 'px'
    });
  }
  
  showPhoto = () => {
    return this.state.croppedPhoto ? this.state.croppedPhoto : this.state.photoUrl;
  }
  
  render () {
    let dropzoneRef;
    const unfilled = Object.keys(this.state.unfulfilled);
    
    return (
      <Container>
        <Modal isOpen={this.state.cropperModal}>
          <ModalHeader toggle={this.closeCropperModal}>
            {I18n.t('order_item.photo')}
          </ModalHeader>
          <ModalBody>
            <Cropper
              ref="cropper"
              src={this.state.photoUrl}
              style={{
                height: '20em',
                width: '100%'
              }}
              aspectRatio={this.props.aspectRatio.x / this.props.aspectRatio.y}
              guides
              viewMode={1}
              crop={this.crop}
            />
          </ModalBody>
          <ModalFooter className="justify-content-start">
            <Button color="primary" onClick={this.acceptPhoto}>
              {I18n.t('photo_field.modal.accept')}
            </Button>
            <Button color="secondary" onClick={this.closeCropperModal} className="btn-link">
              {I18n.t('action.cancel')}
            </Button>
          </ModalFooter>
        </Modal>
        
        <div className="mt-4">
          
          <div>
            <h2 className="mb-3">{this.state.title}</h2>
            {(this.state.viewFields || this.state.justViewPhoto) && <ViewableData viewFields={this.state.viewFields} photo={this.state.justViewPhoto} imgStyle={this.imgStyles(125, 75)} /> }
            <h3 className="mt-5">{I18n.t('customer.action.order_request.edit.field_title')}</h3>
            <div className="align-items-baseline info-box" >
              {I18n.t('customer.action.order_request.edit.info')}
            </div>
          </div>
          
          <Row>
            
            {this.state.usePhoto && (
              <Col xs="3" className="ml-3 mb-5 mr-5">
                <h4 className="mb-3">{I18n.t(`activerecord.attributes.person.photo`)}</h4>
                {this.state.unfulfilled.photo && <InvalidInfo info={this.state.unfulfilled.photo} />}
                <div style={this.imgStyles(450, 250)} className="mb-5">
                  <Dropzone
                    ref={(node) => { dropzoneRef = node; }}
                    onDrop={this.onDrop}
                    multiple={false}
                    className="photo-field"
                    accept="image/jpeg, image/png"
                    style={unfilled.includes('photo') ? { borderColor: 'red' } : {}}
                  >
                    {this.state.photoUrl ? (
                      <img src={this.showPhoto()} height="100%" width="100%" />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center" style={{height: '100%'}}>
                        <span className="text-center dropzone-info">
                          {I18n.t('photo_field.dropzone.info')}
                        </span>
                      </div>
                    )}
                  </Dropzone>
                  <button className="btn btn-outline-primary btn-block mt-1 btn-lg" onClick={() => { dropzoneRef.open(); }}>
                    {this.state.handled ? I18n.t('photo_field.action.change_photo') : I18n.t('photo_field.action.upload_photo')}
                  </button>
                </div>
              </Col>
            )}
            
            <Col xs="8">
              <h4 className="mb-4">{I18n.t('customer.action.order_request.edit.title')}</h4>
              {this.state.fixedFields.map(field => (
                <FieldInput
                  key={field}
                  field={field}
                  value={this.state.fixedFieldsValues[`${field}`]}
                  label={field === 'last_name' || field === 'first_name'
                    ? I18n.t(`activerecord.attributes.person.${field}`)
                    : field.replace('_', ' ')}
                  handleChange={e => this.handleChange(e, true)}
                  invalid={this.state.unfulfilled[field]}
                />
              ))}
              {this.state.extraFields.stringType && this.state.extraFields.stringType.map(field => (
                <FieldInput
                  key={field.id}
                  field={field.id}
                  label={field.name}
                  value={this.state.extraFieldsValues[`${field.id}`]}
                  handleChange={e => this.handleChange(e, false)}
                  invalid={this.state.unfulfilled[field.name]}
                  validationInfo={this.state.validationInfo[field.id]}
                  identifier={field.identifier}
                />
              ))}
              {this.state.extraFields.listType && this.state.extraFields.listType.map(field => (
                <Row key={field.id} className="mt-3">
                  <Col xs="4" className="mt-1">{field.name.charAt(0).toUpperCase() + field.name.slice(1)}</Col>
                  <Col xs="6">
                    <Select
                      style={unfilled.includes(field.name) ? {borderColor: '#dc3545'} : {}}
                      value={{ value: this.state.extraFieldsValues[field.id], label: this.state.extraFieldsValues[field.id] }}
                      onChange={e => this.handleSelectChange(e, field.id)}
                      options={this.createSelectOptions(field)}
                      isClearable={false}
                    />
                  </Col>
                </Row>
              ))}
              {this.state.extraFields.expiration_yearType && this.state.extraFields.expiration_yearType.map(field => (
                <Row key={field.id} className="mt-3">
                  <Col xs="4">{field.name.charAt(0).toUpperCase() + field.name.slice(1)}</Col>
                  <Col xs="6">
                    <Select
                      value={{ value: this.state.extraFieldsValues[field.id], label: this.state.extraFieldsValues[field.id] }}
                      onChange={e => this.handleSelectChange(e, field.id)}
                      options={this.yearOptions()}
                      isClearable={false}
                    />
                  </Col>
                </Row>
              ))}
            </Col>
            
          </Row>
        </div>
        
        <div className="well-stripe" style={{height: '8em'}}>
          <Button color="primary btn-lg pull-right" onClick={this.upload}>
            {I18n.t('customer.action.order_request.edit.save')}
          </Button>
          <button
            type="button"
            className="btn btn-link btn-lg ml-3 pull-right"
            onClick={() => this.backToOrderItemList()}
          >
            {I18n.t('action.back')}
          </button>
        </div>
        
      </Container>
    );
  }
}

OrderRequestApp.propTypes = {
  aspectRatio: PropTypes.object,
  orderRequestProps: PropTypes.object,
  orderRequestsUrl: PropTypes.string
};

export default OrderRequestApp;
