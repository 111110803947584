const loaderReducer = (state = {loader: true}, action) => {
  switch (action.type) {
    case 'LOADER_STATUS':
      return {loader: action.status}
    default:
      return state;
  }
}

export function updateLoaderStatus (type, status) {
  return {
    type: 'LOADER_STATUS',
    status: status
  };
}

export default loaderReducer;
