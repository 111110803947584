import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';

import { I18n } from 'utils';

class TextAlign extends React.Component {
  state = {
    selected: this.props.value || 'left'
  };

  handleOnChange (selected) {
    this.setState({ selected });
    this.props.updateFieldAttribute('align', selected);
  }

  renderIconButtons = () => {
    return ['left', 'center', 'right'].map((style) => {
      return (
        <Button
          color="admin"
          key={style}
          outline
          size="sm"
          style={{ minWidth: '30px', padding: '0.3rem', borderColor: '#ced4da' }}
          onClick={() => this.handleOnChange(style)}
          active={this.state.selected === style}
        >
          <i className={`fa fa-align-${style}`} />
        </Button>
      );
    });
  }

  render () {
    return (
      <div className="mr-2 mb-0 form-group">
        <div>
          <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.text_align')}</small>
        </div>
        <ButtonGroup>
          {this.renderIconButtons()}
        </ButtonGroup>
      </div>
    );
  }
}

TextAlign.propTypes = {
  updateFieldAttribute: PropTypes.func,
  value: PropTypes.string
};

export default TextAlign;
