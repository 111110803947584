export const ADD_CANVAS_FIELD = 'ADD_CANVAS_FIELD';
export const REMOVE_CANVAS_FIELD = 'REMOVE_CANVAS_FIELD';
export const UPDATE_SELECTED_SIDES = 'UPDATE_SELECTED_SIDES';

export function addFieldToCanvas (cardSide, field, text) {
  return {
    type: ADD_CANVAS_FIELD,
    cardSide,
    field,
    text
  };
}

export function removeFieldFromCanvas (cardSide, fieldId) {
  return {
    type: REMOVE_CANVAS_FIELD,
    cardSide,
    fieldId
  };
}

export function updateSelectedSides (selectedSides) {
  return {
    type: UPDATE_SELECTED_SIDES,
    selectedSides
  };
}
