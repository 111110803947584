import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Stage, Layer, Text } from 'react-konva';

import { updateCoordinates } from '../actions/canvas';
import { activateField } from '../actions/designer';
import CardImage from '../components/card_image';
import Transformer from '../components/canvas/transformer_component';

class Canvas extends React.Component {
  constructor (props) {
    super(props);
    this.fieldRefs = {};
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOnBg = this.handleClickOnBg.bind(this);
  }

  // Return frontSide dimensions if no background available
  backgroundProp () {
    let bg = this.props[this.props.activeCardSide].background;
    return bg || { width: this.props['frontSide'].background.width, height: this.props['frontSide'].background.height };
  }

  fieldsProp () {
    return this.props[this.props.activeCardSide].fields;
  }

  handleClickOnBg (e) {
    this.props.activateField(null);
  }

  handleClick (e) {
    if (this.props.activeFieldId != e.target.attrs.name) {
      this.props.activateField(e.target.attrs.name);
    }
  }

  handleDragEnd (e) {
    const shape = e.target;

    let width = shape.width() * shape.scaleX();
    let height = shape.height() * shape.scaleY();

    this.props.updateCoordinates(
      this.props.activeCardSide,
      shape.name(),
      shape.x(),
      shape.y(),
      width,
      height
    );
  }

  renderBackground () {
    let { src, width } = this.backgroundProp();
    return (src && width) && (
      <CardImage
        key="background"
        src={src}
        width={width}
        backgroundImage
        uvSide={['frontUvSide', 'backUvSide'].includes(this.props.activeCardSide)}
      />
    );
  }

  renderText (field) {
    return !field['deleted'] && (
      <Text
        key={field.id}
        name={field.id.toString()}
        text={field.text}
        x={field.x}
        y={field.y}
        wrap={'none'}
        height={field.height}
        width={field.width}
        fontSize={field.fontSize}
        fontFamily={field.fontFamily}
        fontStyle={field.fontStyle}
        align={field.align}
        rotation={field.rotation}
        fill={field.fillColor}
        onClick={this.handleClick}
        draggable={this.props.activeFieldId == field.id}
        onDragEnd={this.handleDragEnd}
        onTransformEnd={this.handleDragEnd}
        ref={(node) => { this.fieldRefs[field.id] = node; }}
        dragBoundFunc={(pos) => { return this.restrictPositionToCanvas(pos, this.fieldRefs[field.id]); }}
      />
    );
  }

  renderPhoto (field) {
    let samplePhoto; // = field.fieldType === 'photo' ? this.props.photoSampleSrc : this.props.barcodeSampleSrc;
    switch (field.fieldType) {
      case 'photo':
        samplePhoto = this.props.photoSampleSrc;
        break;
      case 'code_39':
      case 'code_39e':
      case 'code_128':
        samplePhoto = this.props.barcodeSampleSrc;
        break;
      case 'code_qr':
        samplePhoto = this.props.qrCodeSampleSrc;
        break;
      default:
    }

    return !field['deleted'] && (
      <CardImage
        key={field.id}
        {...field}
        src={field.photo ? field.photo : samplePhoto}
        type={field.fieldType}
        width={field.width}
        height={field.height}
        onDragEnd={this.handleDragEnd}
        onTransformEnd={this.handleDragEnd}
        draggable={this.props.activeFieldId == field.id}
        onClick={this.handleClick}
        ref={(node) => { this.fieldRefs[field.id] = node; }}
        dragBoundFunc={(pos) => { return this.restrictPositionToCanvas(pos, this.fieldRefs[field.id].photoRef); }}
      />
    );
  }

  restrictPositionToCanvas (pos, ref) {
    // itemRect equals the text or image element that is being moved
    const itemRect = {x: ref.x(), y: ref.y(), width: ref.width() * this.props.scale, height: ref.height() * this.props.scale};

    let boundaryRect = {
      left: 0,
      top: 0,
      right: this.stageRef.attrs.width,
      bottom: this.stageRef.attrs.height
    };
    const rotation = ref.rotation();

    switch (rotation) {
      case 0: // for 0 degrees compute as per a normal bounds rect
        boundaryRect.right = boundaryRect.right - itemRect.width;
        boundaryRect.bottom = boundaryRect.bottom - itemRect.height;
        break;
    
      case 90: // for 90 degs we have to modify the boundary left and bottom
        boundaryRect.left = boundaryRect.left + itemRect.height;
        boundaryRect.bottom = boundaryRect.bottom - itemRect.width;
        break;
    
      case 180: // for 180 degs we have to modify the boundary left and top
        boundaryRect.left = boundaryRect.left + itemRect.width;
        boundaryRect.top = boundaryRect.top + itemRect.height;
        break;
    
      case 270: // for 270 degs we have to modify the boundary right and top
        boundaryRect.right = boundaryRect.right - itemRect.height;
        boundaryRect.top = boundaryRect.top + itemRect.width;
        break;
    }
    
    // get new pos as: if pos inside bounday ranges then use it, otherwise user boundary
    
    // left edge check
    let newX = (pos.x < boundaryRect.left ? boundaryRect.left : pos.x);
    
    // right edge check
    newX = (newX > boundaryRect.right ? boundaryRect.right : newX);
    
    // top edge check
    let newY = (pos.y < boundaryRect.top ? boundaryRect.top : pos.y);
    
    // bottom edge check
    newY = (newY > boundaryRect.bottom ? boundaryRect.bottom : newY);
    
    // return the point we calculated
    return {
      x: newX,
      y: newY
    };
  }

  renderFields () {
    return Object.values(this.fieldsProp()).map((field) => {
      switch (field.fieldType) {
        case 'string':
          return this.renderText(field);
        case 'photo':
        case 'code_39':
        case 'code_39e':
        case 'code_128':
        case 'code_qr':
          return this.renderPhoto(field);
        default:
      }
    });
  }

  renderCanvas () {
    // Stage dimensions are the same as background images dimensions
    // If no image found, backgroundProp returns frontSides dimensions
    let { width, height } = this.backgroundProp();
    return (
      <div id="container" className="d-flex" style={{ 'width': '500', 'maxWidth': '500', 'overflow': 'scroll' }}>
        <Stage ref={ref => { this.stageRef = ref; }} scaleX={this.props.scale} scaleY={this.props.scale} name="stage"
          width={width * this.props.scale} height={height * this.props.scale} className="mr-auto ml-auto"
          style={{ 'border': '25px solid #f5f5f5' }}
        >
          <Layer onClick={this.handleClickOnBg}>
            {this.renderBackground()}
          </Layer>
          <Layer>
            {this.fieldsProp() && this.renderFields()}
            <Transformer selectedShapeName={this.props.activeFieldId} onChange={this.handleTransformerChange} />
          </Layer>
        </Stage>
      </div>
    );
  }

  render () {
    return this.backgroundProp() && this.renderCanvas();
  }
}

function mapStateToProps (state) {
  return {
    frontSide: state.canvas.frontSide,
    backSide: state.canvas.backSide,
    frontUvSide: state.canvas.frontUvSide,
    backUvSide: state.canvas.backUvSide,
    activeFieldId: state.designer.activeFieldId,
    activeCardSide: state.designer.activeCardSide,
    photoSampleSrc: state.designer.photoSampleSrc,
    barcodeSampleSrc: state.designer.barcodeSampleSrc,
    qrCodeSampleSrc: state.designer.qrCodeSampleSrc
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateCoordinates,
    activateField
  }, dispatch);
}

Canvas.propTypes = {
  frontSide: PropTypes.shape().isRequired,
  fields: PropTypes.shape(),
  updateCoordinates: PropTypes.func,
  activateField: PropTypes.func,
  activeFieldId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  photoSampleSrc: PropTypes.string,
  barcodeSampleSrc: PropTypes.string,
  qrCodeSampleSrc: PropTypes.string,
  scale: PropTypes.number,
  activeCardSide: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
