import { UPLOAD_BACKGROUND_REQUEST, UPLOAD_BACKGROUND_SUCCESS, UPLOAD_BACKGROUND_FAIL } from '../actions/toolbar';

const initialState = {
  uploadingBackground: false
};

export default function (state, action) {
  state = Object.assign({}, initialState, state);

  switch (action.type) {
    case UPLOAD_BACKGROUND_REQUEST:
      return Object.assign({}, state, {
        uploadingBackground: true
      });
    case UPLOAD_BACKGROUND_SUCCESS:
    case UPLOAD_BACKGROUND_FAIL:
      return Object.assign({}, state, {
        uploadingBackground: false
      });
    default:
      return state;
  }
}
