import React from 'react';
import PropTypes from 'prop-types';

const InvalidInfo = (props) => 
  <span style={{width: '100%', fontSize: '80%', color: '#dc3545', display: 'block'}}>
    {props.info}
  </span>
  
InvalidInfo.propTypes = {
  info: PropTypes.string
};

export default InvalidInfo;