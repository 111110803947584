import React from 'react';
import PropTypes from 'prop-types';
import { Input, Col, Row } from 'reactstrap';
import InvalidInfo from './invalid_info';
import { I18n } from '../../utils';

class FieldInput extends React.Component {
  capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render () {
    return (
      <Row className="mt-3">
        <Col xs="4"> {this.capitalize(this.props.label)} {this.props.identifier && `(${I18n.t('activerecord.attributes.person_field.identifier')})`}</Col>
        <Col xs="6">
          <Input
            key={this.props.field}
            name={this.props.field}
            onChange={this.props.handleChange}
            invalid={this.props.invalid}
            value={this.props.value || ''}
            required
          />
          {this.props.invalid && <InvalidInfo info={this.props.invalid} />}
        </Col>
        <Col><span className="input-info">{this.props.validationInfo}</span></Col>
      </Row>
    );
  }
}

FieldInput.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  invalid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  value: PropTypes.string,
  validationInfo: PropTypes.string,
  identifier: PropTypes.bool
};

export default FieldInput;
