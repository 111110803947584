import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

class ColorPicker extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      displayPicker: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick () {
    this.setState({ displayPicker: !this.state.displayPicker });
  }
  
  handleClose () {
    this.setState({ displayPicker: false });
  }

  handleOnChange (color) {
    this.props.updateFieldAttribute('fillColor', color.hex);
  }

  renderPicker () {
    return (
      <div className="popover">
        <div className="cover" onClick={this.handleClose} />
        <ChromePicker color={this.props.value} onChange={this.handleOnChange} disableAlpha />
      </div>
    );
  }

  render () {
    return (
      <div className="mr-2 mb-0 form-group">
        <div>
          <small className="text-uppercase text-muted">väri</small>
        </div>
        <div className="color-picker" style={{ 'zIndex': '1000' }}>
          <div className="swatch" onClick={this.handleClick}>
            <div className="fill-color" style={{background: this.props.value}} />
          </div>
          { this.state.displayPicker && this.renderPicker() }
        </div>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  updateFieldAttribute: PropTypes.func,
  value: PropTypes.string
};

export default ColorPicker;
