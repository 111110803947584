import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';
import { I18n } from 'utils';
import FixedFieldsModal from '../components/editor/fixed_fields_modal.jsx';
import { removeFieldFromCanvas } from '../actions/editor';
import { activateField } from '../actions/designer';

class FixedFields extends React.Component {
  state = {
    modalOpen: false
  }
  
  fixedFields = (currentCanvasFields) => {
    return Object.values(currentCanvasFields).filter(field => typeof field.id === 'string' && field.id.slice(0, 11) === 'fixed_field' && !field.deleted);
  }
  
  sideIndex = (side) => {
    switch (side) {
      case 'frontSide':
        return 1;
      case 'frontUvSide':
        return 2;
      case 'backSide':
        return 3;
      case 'backUvSide':
        return 4;
    }
  }
  
  // text field form is fixed_field_id and photo field form is fixed_field_photo_id
  // so slice 12 if text and 19 if photo to get id
  sliceIndexToGetId = (photo) => {
    const index = photo ? 19 : 12;
    return index;
  }
  
  generateNewFieldId = () => {
    const fields = this.fixedFields(this.props.canvasFields);
    
    // if there is any fixed fields get all ids and return highest + 1
    const id = fields.length > 0 ?
      fields
        .map((field) =>
          Number(field.id.slice(this.sliceIndexToGetId(field.fieldType === 'photo'))))
        .reverse()[0] + 1 :
      0;
    return id;
  }
  
  addFixedField = (text, photo = null) => {
    const id = this.generateNewFieldId();
    let field = {
      id: `fixed_field_${photo ? `photo_${this.sideIndex(this.props.activeCardSide)}${id}` : id}`,
      name: 'fixed_field',
      text: text.length > 0 ? text : `photo ${id}`,
      fieldType: photo ? 'photo' : 'string'
    };
    if (photo) {
      this.addPhotoField(field, photo, id);
    } else {
      field = Object.assign({}, field, {
        text: text,
        fontSize: 20,
        fillColor: '#000000',
        fontFamily: 'Arimo'
      });
      this.setToCanvas(field);
    }
  }
  
  addPhotoField = (field, photo) => {
    const reader = new window.FileReader();
    reader.onload = (event) => {
      field = Object.assign({}, field, {
        height: 50,
        width: photo.width * (50 / photo.height),
        aspectRatioX: photo.width,
        aspectRatioY: photo.height,
        x: 10,
        y: 10,
        photo: event.target.result
      });
      this.setToCanvas(field);
    };
    reader.readAsDataURL(photo);
  }
  
  setToCanvas = (field) => {
    this.props.addToCanvas(field);
    this.toggleModal();
    this.props.activateField(field.id);
    this.setState({
      selected: field.id
    });
  }
  
  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  render () {
    const fixedFields = this.fixedFields(this.props.canvasFields);
    return (
      <div className="pb-3">
        <span className="text-muted text-uppercase">
          {I18n.t('card_layout.fixed_fields.title')}
        </span>
        <Button color="link" onClick={this.toggleModal} className="p-1">
          <i className="fa fa-plus ml-1" style={{marginBottom: '0.47em'}} />
        </Button>
        <div className="bg-white">
          <ListGroup>
            {fixedFields.map(field =>
              <ListGroupItem key={field.id} active={field.id === this.props.activeFieldId}>
                <span onClick={() => this.props.activateField(field.id)} style={{ 'cursor': 'pointer' }}>
                  {field.text.length > 10 ? field.text.slice(0, 10) + '...' : field.text}
                </span>
                <Button
                  className="float-right btn-list pt-0 pb-0"
                  onClick={() => this.props.removeFieldFromCanvas(this.props.activeCardSide, field.id)}
                  size="sm"
                  color={'danger'}
                >
                -
                </Button>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
        <FixedFieldsModal
          modalOpen={this.state.modalOpen}
          toggleModal={this.toggleModal}
          addField={this.addFixedField}
        />
      </div>
    );
  }
}

FixedFields.propTypes = {
  addToCanvas: PropTypes.func.isRequired,
  activateField: PropTypes.func.isRequired,
  activeFieldId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  removeFieldFromCanvas: PropTypes.func.isRequired,
  activeCardSide: PropTypes.string.isRequired,
  canvasFields: PropTypes.object.isRequired
};

function mapStateToProps (state) {
  return {
    canvasFields: state.canvas[state.designer.activeCardSide].fields,
    activeCardSide: state.designer.activeCardSide,
    selectedSides: state.editor.selectedSides,
    activeFieldId: state.designer.activeFieldId
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    removeFieldFromCanvas,
    activateField
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FixedFields);
