import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OrderLine from './order-line';
import { I18n } from '../../../utils';

const OrderLines = (props) => {
  const { orderLines, setOrderTotalPrice } = props;

  let totalPrice = 0.0;
  let totalPriceWithoutVat = 0.0;
  let totalVat = 0.0;

  Object.values(orderLines).forEach((line) => {
    totalPrice += parseFloat(line.total_price);
    totalPriceWithoutVat += parseFloat(line.price);
    totalVat += parseFloat(line.vat_total);
  });

  useEffect(() => {
    setOrderTotalPrice(totalPrice);
  }, [orderLines]);

  return (
    <div>
      <table id="checkout-summary" className="table mobile-orders-table mt-2">
        <thead>
          <tr>
            <th width={300}>{I18n.t('order_item.product')}</th>
            <th className="text-right">
              Á-{I18n.t('activerecord.attributes.product.price').downcase} ({I18n.t('general.vat').downcase} 0 %)
            </th>
            <th className="text-right">{I18n.t('general.vat')} %</th>
            <th className="text-right">{I18n.t('activerecord.attributes.order_item.amount')}</th>
            <th className="text-right">{I18n.t('general.products')} €</th>
            <th className="text-right">{I18n.t('general.vat')} €</th>
            <th className="text-right">{I18n.t('general.subtotal')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(orderLines).map((cardId) => <OrderLine key={cardId} line={orderLines[cardId]} />)}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={4}>{I18n.t('general.total')}</th>
            <th className="text-right font-weight-normal">{totalPriceWithoutVat.toFixed(2)} €</th>
            <th className="text-right font-weight-normal">{totalVat.toFixed(2)} €</th>
            <th colSpan={2} className="text-right">{totalPrice.toFixed(2)} €</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

OrderLines.propTypes = {
  orderLines: PropTypes.shape(),
  setOrderTotalPrice: PropTypes.func.isRequired
};

export default OrderLines;
