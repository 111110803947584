import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateLoaderStatus } from '../reducers/loader';

import { ColumnChart, PieChart } from 'react-chartkick';
import 'chart.js';
import request from 'then-request';

import { datalabelOptions } from '../utils';

class TotalOrders extends React.Component {
  state = {
    data: [],
    pieData: {},
    total: 0
  }

  componentDidMount = () => {
    if (this.props.active) {
      this.fetchData();
    }
  }

  componentDidUpdate = (prevProps) => {
    let sourceChanged = prevProps.sourceWithUrlParams !== this.props.sourceWithUrlParams;
    if ((!prevProps.active && this.props.active) || (this.props.active && sourceChanged)) {
      this.fetchData();
    }
  }

  fetchData = () => {
    request('GET', this.props.sourceWithUrlParams).done((res) => {
      const data = JSON.parse(res.getBody());
      let total = 0;
      let pieData = {};
      if (data.length > 0) {
        data.forEach((array) => {
          let subTotal = Object.values(array.data).reduce((acc, val) => (acc + val[1]), 0);
          total += subTotal;
          array.name += ` (${subTotal})`;
          pieData[array.name] = subTotal;
        });
      }
      this.props.updateLoaderStatus('LOADER_STATUS', false);
      this.setState({ data, pieData, total });
    });
  }

  render () {
    return (
      <div>
        <div className="row d-flex align-items-center ml-2">
          <ColumnChart
            data={this.state.data}
            id="order-items-total"
            library={{
              animation: { duration: 1000 },
              ...datalabelOptions
            }}
            stacked
            download={{ background: '#ffff' }}
            title={`${this.props.title} (${this.state.total})`}
          />
        </div>
        <div className="row d-flex align-items-center ml-2">
          <PieChart
            data={this.state.pieData}
            id="order-items-total"
            library={{ animation: { duration: 1000 }, ...datalabelOptions }}
            download={{ background: '#ffff' }}
          />
        </div>
      </div>
    );
  }
}

TotalOrders.propTypes = {
  sourceWithUrlParams: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool.isRequired,
  updateLoaderStatus: PropTypes.func
};

export default connect(null, { updateLoaderStatus })(TotalOrders);
