import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';

class CardImages extends Component {
  constructor (props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
  }
  
  UNSAFE_componentWillMount = () => {
    let items = this.props.images.map(photo => { return { src: photo.url, caption: '' }; });
    this.setState({items});
  }

  next () {
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous () {
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex (newIndex) {
    this.setState({ activeIndex: newIndex });
  }

  render () {
    const { activeIndex } = this.state;

    let slides = this.state.items.map((item) => {
      return (
        <CarouselItem
          key={item.src}
        >
          <img
            src={item.src}
            alt={item.altText}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              height: '12em'
            }}
          />
        </CarouselItem>
      );
    });

    if (slides.length === 0) {
      slides = [
        <CarouselItem
          key={this.props.placeholder}
        >
          <img
            src={this.props.placeholder}
            alt={this.props.placeholder}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'center',
              justifyContent: 'center',
              height: '12em'
            }}
          />
        </CarouselItem>
      ]
    }

    return (
      <div className="w-100">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval={false}
        >
          {slides}
          {slides.length > 1 &&
            <div>
              <CarouselIndicators items={this.state.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} style={{marginTop: '5em;'}} />
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </div>
          }
        </Carousel>
      </div>
    );
  }
}

CardImages.propTypes = {
  images: PropTypes.array,
  placeholder: PropTypes.string
};

export default CardImages;