import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Select from 'components/select';

import { uploadBackground } from '../../actions/toolbar';

import { I18n } from 'utils';
import { Button, Card, CardBody, CardTitle,
  Label, Input, FormGroup, Col } from 'reactstrap';
import Dropzone from '../../components/dropzone';

class InitialSettings extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      file: null,
      aspectRatioValue: 'cr80_h',
      aspectRatioX: this.props.cardInfo.aspectRatioX || '85.60',
      aspectRatioY: this.props.cardInfo.aspectRatioY || '53.98'
    };
    this.onDrop = this.onDrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleAspectRatioSelectChange = this.handleAspectRatioSelectChange.bind(this);
    this.aspectRatioOnChange = this.aspectRatioOnChange.bind(this);
    this.onCropEnd = this.onCropEnd.bind(this);
  }

  onDrop (files) {
    this.setState({
      file: files[0]
    });
  }

  onCropEnd (e) {
    let ratios = {};
    if (this.state.aspectRatioValue === 'custom') {
      ratios = {
        aspectRatioX: e.target.cropper.cropBoxData.width.toString(),
        aspectRatioY: e.target.cropper.cropBoxData.height.toString()
      };
    }
    this.setState(Object.assign({}, ratios, {
      cropBoxData: e.target.cropper.getCropBoxData()
    }));
  }

  handleUpload () {
    let imageType = this.state.file.type;
    let imageName = this.state.file.name;
    let image = this.refs.cropper.getCroppedCanvas().toDataURL(imageType, 1);
    this.props.uploadBackground(this.props.activeCardSide, image, imageName, this.props.layoutId);
    window.URL.revokeObjectURL(this.state.file.preview);
    this.props.completeInitialSetup(this.state.aspectRatioX, this.state.aspectRatioY);
  }

  handleAspectRatioSelectChange ({ value, _label }) {
    this.setState({ aspectRatioValue: value });
    switch (value) {
      case 'cr80_h':
        this.setState({
          aspectRatioX: '85.60',
          aspectRatioY: '53.98'
        });
        break;
      case 'cr80_v':
        this.setState({
          aspectRatioX: '53.98',
          aspectRatioY: '85.60'
        });
        break;
      default:
        let cropBoxData = this.refs.cropper.getCropBoxData();
        this.setState({
          aspectRatioX: cropBoxData.width,
          aspectRatioY: cropBoxData.height,
          cropBoxData
        });
        break;
    }
  }

  aspectRatioOnChange (event) {
    let axis = event.target.name === 'aspectRatioX' ? 'width' : 'height';
    let value = event.target.value;
    let cropBoxData = Object.assign({}, this.refs.cropper.getCropBoxData(), {
      width: parseFloat((axis === 'width' ? value : this.state.aspectRatioX)),
      height: parseFloat((axis === 'height' ? value : this.state.aspectRatioY))
    });
    this.setState({
      [event.target.name]: value,
      cropBoxData,
      aspectRatioValue: 'custom'
    });
  }

  renderAspectRatioSelect () {
    let options = [
      { value: 'custom', label: I18n.t('card_layout.custom_format') },
      { value: 'cr80_h', label: 'CR-80 Horizontal (85.60 × 53.98 mm)' },
      { value: 'cr80_v', label: 'CR-80 Vertical (53.98 × 85.60 mm)' }
    ];
    
    return (
      <FormGroup row>
        <Label sm={4}>{I18n.t('card_layout.format')}</Label>
        <Col sm={8}>
          <Select
            options={options}
            value={options.filter(({ value }) => value === this.state.aspectRatioValue)}
            onChange={this.handleAspectRatioSelectChange}
            isClearable={false}
            isSearchable={false}
            isDisabled={!this.state.file}
          />
        </Col>
      </FormGroup>
    );
  }

  renderCropper () {
    // If custom format aspect ratio is changeable, otherwise it is fixed
    let aspectRatio = this.state.aspectRatioValue === 'custom' ? NaN : (this.state.aspectRatioX / this.state.aspectRatioY);
    return (
      <div className="mr-auto ml-auto">
        <Cropper
          ref="cropper"
          src={this.state.file.preview}
          aspectRatio={aspectRatio}
          viewMode={1}
          cropBoxResizable
          cropBoxMovable
          dragMode="move"
          zoomOnWheel
          zoomOnTouch
          cropend={this.onCropEnd}
          cropBoxData={this.state.cropBoxData}
        />
      </div>
    );
  }

  render () {
    return (
      <div>
        <Card>
          <CardBody>
            <CardTitle>{this.props.cardInfo.name}</CardTitle>
            <div className="info-box flex-column align-items-baseline pt-4">
              {I18n.t('card_layout.initial_settings.info_text')}
              <ul>
                <li>{I18n.t('card_layout.initial_settings.info_bullet1')}</li>
                <li>{I18n.t('card_layout.initial_settings.info_bullet2')}</li>
                <li>{I18n.t('card_layout.initial_settings.info_bullet3')}</li>
              </ul>
            </div>
            <div className="d-flex">
              <div className="col-8">
                <CardTitle>{I18n.t('card_layout.background.front_title')}</CardTitle>
                {this.state.file ? this.renderCropper() : <Dropzone onDrop={this.onDrop} style={{width: '100%'}} />}
              </div>
              <div className="col-4">
                <CardTitle>{I18n.t('card_layout.aspect_ratio')}</CardTitle>
                {this.renderAspectRatioSelect()}
                <FormGroup row>
                  <Label sm={4}>X</Label>
                  <Col sm={8}>
                    <Input
                      type="number"
                      onChange={this.aspectRatioOnChange}
                      name="aspectRatioX"
                      value={this.state.aspectRatioX}
                      disabled={!this.state.file} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={4}>Y</Label>
                  <Col sm={8}>
                    <Input
                      type="number"
                      onChange={this.aspectRatioOnChange}
                      name="aspectRatioY"
                      value={this.state.aspectRatioY}
                      disabled={!this.state.file} />
                  </Col>
                </FormGroup>
              </div>
            </div>
            <Button className="mt-5 float-right" color="primary" onClick={this.handleUpload} disabled={this.props.uploading} >
              {I18n.t('action.save')}
              {this.props.uploading && <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    cardId: state.designer.cardId,
    cardInfo: state.designer.cardInfo,
    layoutId: state.designer.layoutId,
    activeCardSide: state.designer.activeCardSide,
    uploading: state.toolbar.uploadingBackground
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    uploadBackground
  }, dispatch);
}

InitialSettings.propTypes = {
  completeInitialSetup: PropTypes.func,
  uploadBackground: PropTypes.func,
  uploading: PropTypes.bool,
  layoutId: PropTypes.number,
  activeCardSide: PropTypes.string,
  cardInfo: PropTypes.shape()
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialSettings);
