import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import request from 'then-request';

import OrderLines from './checkout/order-lines';
import DeliveryOptions from './checkout/delivery-options';
import { I18n } from '../../utils';

const Checkout = (props) => {
  const {
    countries,
    delivery: { methods: deliveryMethods, messages: deliveryMessages },
    formAuthenticityToken,
    href,
    order,
    webshop
  } = props;

  const [delivery, setDelivery] = useState(order.pickup ? 'pickup' : 'shipping');
  const [shippingAddress, setShippingAddress] = useState({
    company: '',
    name: '',
    street: '',
    zip: '',
    city: '',
    country: 'FI'
  });
  const [orderLines, setOrderLines] = useState(order.orderLines);
  const [errors, setErrors] = useState({});
  const [orderTotalPrice, setOrderTotalPrice] = useState(null);
  const [orderErrors, setOrderErrors] = useState([]);

  // Minimum price restriction is dynamically handled
  useEffect(() => {
    const result = [];
    if (!order.paid) {
      if (!order.payerMatch) {
        result.push(I18n.t('order.online_payment.payer_warning'));
      }
  
      if (orderTotalPrice < props.minPrice) {
        result.push(I18n.t('order.online_payment.price_warning', { minPrice: props.minPrice }));
      }
    }
    setOrderErrors(result);
  }, [orderTotalPrice]);

  const handleDeliveryChange = (delivery) => {
    setDelivery(delivery);
    let newOrderLines = {...orderLines, ...{ postages: deliveryMethods[delivery] }};
    setOrderLines(newOrderLines);
  };

  const changeShippingAddress = (property, value) => {
    let newAddress = {...shippingAddress};
    newAddress[property] = value;
    setShippingAddress(newAddress);
  };

  // Determine if user is redirected directly to the pay-link
  const handleSubmit = () => {
    if (webshop && !order.paymentPending) {
      handleDeliverMethodPost();
    } else {
      handlePayOrder();
    }
  };

  // Post delivery method and address
  const handleDeliverMethodPost = () => {
    request(
      'POST',
      href,
      {
        json: {
          ...shippingAddress,
          authenticity_token: formAuthenticityToken,
          deliveryMethod: delivery
        }
      }
    ).done((res) => {
      const body = JSON.parse(res.body);
      if (res.statusCode === 200) {
        window.location.href = body.redirectTo;
      } else {
        setErrors(body.missingFields);
      }
    })
    ;
  };

  // Used when payment is pending or not a webshop order
  const handlePayOrder = () => {
    let confirmed = true;
    if (order.paymentPending) {
      confirmed = window.confirm(I18n.t('online_payment_event.pending_payment_info'));
    }
    if (confirmed) {
      window.location.href = href;
    }
  };

  // Cannot pay if other user has started to pay or total price too low
  const canPay = !order.paid && !orderErrors.length;

  return (
    <div>
      <OrderLines orderLines={orderLines} setOrderTotalPrice={setOrderTotalPrice} />
      <DeliveryOptions
        countries={countries}
        delivery={delivery}
        deliveryAddress={order.webshopAddress}
        deliveryLocked={!webshop || order.paid || order.paymentPending}
        handleDeliveryChange={handleDeliveryChange}
        shippingAddress={shippingAddress}
        changeShippingAddress={changeShippingAddress}
        deliveryMessages={deliveryMessages}
        errors={errors}
      >
        <>
          {canPay && (
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {I18n.t('general.order_and_pay')}
            </button>
          )}
          {!!orderErrors.length && (
            orderErrors.map((error, i) => (
              <div key={i.toString()} className="info-box info-box-danger bg-white">
                {error}
              </div>
            ))
          )}
        </>
      </DeliveryOptions>
    </div>
  );
};

Checkout.defaultProps = {
};

Checkout.propTypes = {
  countries: PropTypes.array.isRequired,
  delivery: PropTypes.shape({
    methods: PropTypes.shape().isRequired,
    messages: PropTypes.shape().isRequired
  }),
  formAuthenticityToken: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  minPrice: PropTypes.number.isRequired,
  order: PropTypes.shape({
    errors: PropTypes.arrayOf(PropTypes.string),
    orderLines: PropTypes.shape().isRequired,
    paid: PropTypes.bool.isRequired,
    payerMatch: PropTypes.bool.isRequired,
    paymentPending: PropTypes.bool.isRequired,
    pickup: PropTypes.bool.isRequired,
    webshopAddress: PropTypes.shape()
  }),
  webshop: PropTypes.bool.isRequired
};

export default Checkout;
