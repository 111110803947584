import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalBody, ListGroup, ListGroupItem,
  ModalFooter, ModalHeader, Label } from 'reactstrap';
import { I18n } from 'utils';

class SideSelectModal extends React.Component {
  state = {
    selected: this.props.selectedSides
  }

  toggleSide = (side) => {
    let selected = Object.assign([], this.state.selected);

    if (this.state.selected.includes(side)) {
      selected.splice(this.state.selected.indexOf(side), 1);
    } else {
      selected.push(side);
    }
    this.setState({ selected });
  }

  onSave = () => {
    this.props.updateSelectedSides(this.state.selected);
    this.props.toggle();
  }

  renderItems = () => {
    let availableSides = ['frontUvSide', 'backSide', 'backUvSide'];
    return availableSides.map((side) => {
      let translation = 'card_layout.editor.' + side.replace('Side', '_side');
      let active = this.state.selected.includes(side);
      return (
        <ListGroupItem
          key={side}
          onClick={() => this.toggleSide(side)}
          active={active}
          action
          className="mb-1 d-flex"
          style={{ cursor: 'pointer' }}
        >
          {I18n.t(translation)}
          {active && <i className="fa fa-check ml-auto" />}
        </ListGroupItem>
      );
    });
  }

  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <ModalHeader toggle={this.props.toggle}>{I18n.t('card_layout.editor.card_sides')}</ModalHeader>
        <ModalBody>
          <div className="info-box">
            {I18n.t('card_layout.editor.card_sides_info')}
          </div>
          <Label>{I18n.t('action.choose')}</Label>
          <ListGroup>
            <ListGroupItem active action className="mb-1 d-flex untouchable">
              {I18n.t('card_layout.editor.front_side')}
              <i className="fa fa-check ml-auto" />
            </ListGroupItem>
            {this.renderItems()}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>{I18n.t('action.cancel')}</Button>
          <Button color="primary" onClick={this.onSave}>{I18n.t('action.save')}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

SideSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  updateSelectedSides: PropTypes.func.isRequired,
  selectedSides: PropTypes.array.isRequired
};

export default SideSelectModal;
