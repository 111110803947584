import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { uploadBackground } from '../../actions/toolbar';

import { I18n } from 'utils';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from '../../components/dropzone';

class BackgroundUpload extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      file: null
    };
    this.closeModal = this.closeModal.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  onDrop (files) {
    this.setState({
      file: files[0]
    });
  }

  handleUpload () {
    let imageType = this.state.file.type;
    let imageName = this.state.file.name;
    let image = this.refs.cropper.getCroppedCanvas().toDataURL(imageType, 1);
    this.props.uploadBackground(this.props.activeCardSide, image, imageName, this.props.layoutId);
    window.URL.revokeObjectURL(this.state.file.preview);
    this.closeModal();
  }

  closeModal () {
    this.setState({ file: null });
    this.props.showModal(false);
  }

  renderCropper () {
    return (
      <div className="mr-auto ml-auto" style={{'width': '80%'}}>
        <Cropper
          ref="cropper"
          src={this.state.file.preview}
          aspectRatio={this.props.aspectRatioX / this.props.aspectRatioY}
          viewMode={1}
          autoCrop
          autoCropArea={1}
          background={false}
          cropBoxResizable={false}
          cropBoxMovable={false}
          dragMode="move"
          zoomOnWheel={false}
          zoomOnTouch={false}
        />
      </div>
    );
  }

  render () {
    return (
      <Modal isOpen={this.props.modalOpen} size="lg">
        <ModalHeader toggle={this.closeModal}>
          Taustakuva
        </ModalHeader>
        <ModalBody>
          {this.state.file ? this.renderCropper() : <Dropzone onDrop={this.onDrop} />}
        </ModalBody>
        <ModalFooter className="justify-content-start">
          <Button color="primary" onClick={this.handleUpload} disabled={this.props.uploading} >
            Aseta taustakuvaksi
            {this.props.uploading && <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
          </Button>
          <Button color="secondary" className="btn-link" onClick={this.closeModal} disabled={this.props.uploading} >
            {I18n.t('action.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps (state) {
  return {
    cardId: state.designer.cardId,
    layoutId: state.designer.layoutId,
    activeCardSide: state.designer.activeCardSide,
    uploading: state.toolbar.uploadingBackground,
    aspectRatioX: state.designer.cardInfo.aspectRatioX,
    aspectRatioY: state.designer.cardInfo.aspectRatioY
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    uploadBackground
  }, dispatch);
}

BackgroundUpload.propTypes = {
  showModal: PropTypes.func,
  uploadBackground: PropTypes.func,
  modalOpen: PropTypes.bool,
  uploading: PropTypes.bool,
  layoutId: PropTypes.number,
  activeCardSide: PropTypes.string,
  aspectRatioX: PropTypes.string,
  aspectRatioY: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundUpload);
