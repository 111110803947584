import React from 'react';
import PropTypes from 'prop-types';

import { Button, ListGroupItem } from 'reactstrap';
import { I18n } from 'utils';

class SingleField extends React.Component {
  constructor (props) {
    super(props);
    this.addToCanvas = this.addToCanvas.bind(this);
    this.removeFromCanvas = this.removeFromCanvas.bind(this);
    this.activateField = this.activateField.bind(this);
  }

  activateField () {
    if (this.props.activated) {
      this.props.activateField(null);
    } else if (this.props.selected) {
      this.props.activateField(this.props.id);
    } else {
      this.addToCanvas();
    }
  }

  fieldWithDefaults () {
    let field = (({ id, name, fieldType }) => ({ id, name, fieldType }))(this.props);
    switch (field.fieldType) {
      case 'string':
        field = Object.assign({}, field, {
          text: `${this.props.name} ${I18n.t('card_layout.toolbar.field_example_text')}`,
          fontSize: 20,
          fillColor: '#000000',
          fontFamily: 'Arimo',
          x: 25,
          y: 25,
          width: 200,
          height: 20
        });
        break;
      case 'photo':
        let { addToCanvas, removeFromCanvas, ...photoFields } = this.props;
        field = Object.assign({}, field, {
          ...photoFields,
          width: 200,
          height: 200,
          x: 25,
          y: 25,
        });
        break;
      default:
    }
    return field;
  }

  addToCanvas () {
    this.props.addToCanvas(this.fieldWithDefaults());
    this.props.activateField(this.props.id);
  }

  removeFromCanvas () {
    this.props.removeFromCanvas(this.props.id);
    this.props.activateField(null);
  }

  render () {
    let { selected, activated } = this.props;
    return (
      <ListGroupItem active={activated}>
        <span onClick={this.activateField} style={{ 'cursor': 'pointer' }}>
          {this.props.name}
        </span>
        <Button
          className="float-right btn-list pt-0 pb-0"
          onClick={selected ? this.removeFromCanvas : this.addToCanvas}
          size="sm"
          color={selected ? 'danger' : 'primary'}
        >
          {selected ? '-' : '+'}
        </Button>
      </ListGroupItem>
    );
  }
}

SingleField.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  name: PropTypes.string,
  fieldType: PropTypes.string,
  addToCanvas: PropTypes.func,
  removeFromCanvas: PropTypes.func,
  activateField: PropTypes.func,
  selected: PropTypes.bool,
  activated: PropTypes.bool
};

export default SingleField;
