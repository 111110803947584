import { $, I18n } from './utils';

export default function init () {
  $('#card_hangers.select2, #default_card_hangers.select2').select2({
    theme: 'bootstrap',
    closeOnSelect: true,
    tags: false,
    multiple: true,
    placeholder: I18n.t('action.choose'),
    templateResult: formatSelection,
    language: {
      noResults: () => { return I18n.t('select.no_results'); }
    }
  });

  function formatSelection (state) {
    if (!state.id) { return state.text; }
    let title = state.text.replace(/\([^)]+\)/, '');
    let $state = $(
      '<div class="row"><div class="col-9"><div>' + title + '</div><div><i class="text-muted">' + state.element.dataset.code + '</i></div></div><div class="col-3"><img src="' + state.element.dataset.photoUrl + '" class="ml-auto pull-right mr-2 img-fluid product-select-preview" />'
    );

    return $state;
  }

  $('#card_hangers.select2').change(() => {
    const defaultHangerSelect = $('#default_card_hangers.select2');
    const selectedDefaults = defaultHangerSelect.val();
    defaultHangerSelect.empty().trigger('change');
    const cardHangerSelect = $('#card_hangers.select2');
    const selections = cardHangerSelect.select2('data');
    selections.forEach(s => {
      defaultHangerSelect.append($('<option>', { value: s.id, text: s.text, 'data-photo-url': s.element.dataset.photoUrl, 'data-code': s.element.dataset.code }));
    });
    if (selectedDefaults) {
      selectedDefaults.forEach(s => {
        if (cardHangerSelect.val() && cardHangerSelect.val().includes(s)) {
          defaultHangerSelect.val(s);
        }
      });
    }
  });
}
