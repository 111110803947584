import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { ListGroup, Button } from 'reactstrap';

import SingleField from '../components/editor/single_field';
import SideSelectModal from '../components/editor/side_select_modal';
import SideSelector from '../components/editor/side_selector';
import FixedFields from './fixed_fields';
import { addFieldToCanvas, removeFieldFromCanvas, updateSelectedSides } from '../actions/editor';
import { updateCoordinates } from '../actions/canvas';
import { activateField, changeCardSideTo } from '../actions/designer';
import { I18n } from 'utils';

class Editor extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activeCardSide: this.props.activeCardSide,
      sideSettingsOpen: false
    };
    this.addToCanvas = this.addToCanvas.bind(this);
    this.removeFromCanvas = this.removeFromCanvas.bind(this);
    this.activateField = this.activateField.bind(this);
    this.onCardSideClick = this.onCardSideClick.bind(this);
    this.sideSettingsToggle = this.sideSettingsToggle.bind(this);
  }

  onCardSideClick (cardSide) {
    this.props.changeCardSideTo(cardSide);
    this.setState({ activeCardSide: cardSide });
  }

  addToCanvas (field, text = null) {
    let { activeCardSide } = this.props;
    this.props.addFieldToCanvas(activeCardSide, field, text);
    if (!Object.keys(this.props.canvasFields).includes(field.id.toString())) {
      if (field.name === 'fixed_field' && field.fieldType === 'photo') {
        this.props.updateCoordinates(activeCardSide, field.id, 25, 25, field.width, field.height);
      } else {
        this.props.updateCoordinates(activeCardSide, field.id, 25, 25, 205, 20);
      }
    }
  }

  removeFromCanvas (fieldId) {
    this.props.removeFieldFromCanvas(this.props.activeCardSide, fieldId);
  }

  activateField (fieldId) {
    this.props.activateField(fieldId);
  }

  sideSettingsToggle () {
    this.setState({ sideSettingsOpen: !this.state.sideSettingsOpen });
  }

  renderPhotoField () {
    let { photoField } = this.props;
    let canvasField = this.props.canvasFields[photoField.id];
    let selected = canvasField && !canvasField['deleted'];

    return (
      <SingleField {...photoField}
        key={photoField.id}
        addToCanvas={this.addToCanvas}
        removeFromCanvas={this.removeFromCanvas}
        selected={selected}
        activateField={this.activateField}
        activated={this.props.activeFieldId == photoField.id}
      />
    );
  }

  renderFields () {
    return Object.values(this.props.availableFields).map((field) => {
      let selected = this.props.canvasFields[field.id.toString()] && !this.props.canvasFields[field.id.toString()]['deleted'];
      return (
        <SingleField {...field}
          key={field.id}
          addToCanvas={this.addToCanvas}
          removeFromCanvas={this.removeFromCanvas}
          selected={selected}
          activateField={this.activateField}
          activated={this.props.activeFieldId == field.id}
        />);
    });
  }

  render () {
    return (
      <div>
        <div className="pb-3">
          <div className="d-flex align-items-center">
            <span className="text-muted text-uppercase">
              {I18n.t('card_layout.editor.card_sides')}
            </span>
            <Button color="link" onClick={this.sideSettingsToggle} className="p-1">
              <i className="fa fa-cog ml-1" />
            </Button>
          </div>
          <div className="bg-white">
            <SideSelector
              activeCardSide={this.state.activeCardSide}
              onCardSideClick={this.onCardSideClick}
              selectedSides={this.props.selectedSides}
            />
          </div>
        </div>
        <div className="pb-3">
          <span className="text-muted text-uppercase">{I18n.t('card_layout.editor.fields')}</span>
          <div className="bg-white">
            <ListGroup>
              {this.props.photoField && this.renderPhotoField()}
              {this.renderFields()}
            </ListGroup>
          </div>
        </div>
        <FixedFields addToCanvas={this.addToCanvas} />
        <SideSelectModal
          toggle={this.sideSettingsToggle}
          isOpen={this.state.sideSettingsOpen}
          updateSelectedSides={this.props.updateSelectedSides}
          selectedSides={this.props.selectedSides} />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    availableFields: state.editor.availableFields,
    photoField: state.editor.photoField,
    canvasFields: state.canvas[state.designer.activeCardSide].fields,
    activeFieldId: state.designer.activeFieldId,
    activeCardSide: state.designer.activeCardSide,
    selectedSides: state.editor.selectedSides
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    addFieldToCanvas,
    removeFieldFromCanvas,
    updateCoordinates,
    activateField,
    changeCardSideTo,
    updateSelectedSides
  }, dispatch);
}

Editor.propTypes = {
  availableFields: PropTypes.shape(),
  photoField: PropTypes.shape(),
  canvasFields: PropTypes.shape(),
  addFieldToCanvas: PropTypes.func,
  removeFieldFromCanvas: PropTypes.func,
  updateCoordinates: PropTypes.func,
  changeCardSideTo: PropTypes.func,
  updateSelectedSides: PropTypes.func,
  activateField: PropTypes.func,
  activeFieldId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  activeCardSide: PropTypes.string.isRequired,
  selectedSides: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
