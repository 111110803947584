import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'reactstrap';
import { I18n } from '../../utils';

class ViewableData extends React.Component {
  render () {
    return (
      <Row>
        <Col xs="6">
          <Table className="border">
            <tbody>
              <tr>
                <th>{I18n.t('customer.action.order_request.data_box_label')}</th>
                <th className="pull-right">
                  {this.props.photo &&
                    <img style={this.props.imgStyle} src={this.props.photo} />
                  }
                </th>
              </tr>
              {Object.keys(this.props.viewFields).map(field =>
                <tr key={field}>
                  <th className="p-0 pl-2">{field}: </th>
                  <td className="p-0 pr-2" style={{borderBottom: 0}}>{this.props.viewFields[field]}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

ViewableData.propTypes = {
  viewFields: PropTypes.object,
  photo: PropTypes.string,
  imgStyle: PropTypes.object
};

export default ViewableData;
