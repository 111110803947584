import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';
import { I18n } from 'utils';

class FontStyle extends React.Component {
  state = {
    selected: this.props.value || 'normal'
  };

  handleOnChange (selected) {
    this.setState({ selected });
    this.props.updateFieldAttribute('fontStyle', selected);
  }

  renderIconButtons = () => {
    return ['normal', 'bold', 'italic'].map((style) => {
      let classes = classNames('fa', {
        'fa-font': style === 'normal',
        'fa-bold': style === 'bold',
        'fa-italic': style === 'italic'
      });
      return (
        <Button
          color="admin"
          key={style}
          outline
          size="sm"
          style={{ minWidth: '30px', padding: '0.3rem', borderColor: '#ced4da' }}
          onClick={() => this.handleOnChange(style)}
          active={this.state.selected === style}
        >
          <i className={classes} />
        </Button>
      );
    });
  }

  render () {
    return (
      <div className="mr-2 mb-0 form-group">
        <div>
          <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.font_style')}</small>
        </div>
        <ButtonGroup>
          {this.renderIconButtons()}
        </ButtonGroup>
      </div>
    );
  }
}

FontStyle.propTypes = {
  updateFieldAttribute: PropTypes.func,
  value: PropTypes.string
};

export default FontStyle;
