import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'utils';

export const FieldReset = (props) => {
  return (
    <div className="ml-auto mb-0 mr-2 form-group">
      <div>
        <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.reset_field')}</small>
      </div>
      <button className="btn btn-outline-admin btn-sm form-control form-control-sm w-50" onClick={props.resetCoordinates} style={{ 'cursor': 'pointer', 'padding': '0.3rem' }}>
        <i className="fa fa-refresh" />
      </button>
    </div>
  );
};

FieldReset.propTypes = {
  resetCoordinates: PropTypes.func.isRequired
};

export default FieldReset;
