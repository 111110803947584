import { combineReducers } from 'redux';

import canvas from './canvas';
import designer from './designer';
import editor from './editor';
import toolbar from './toolbar';

export default combineReducers({
  canvas,
  designer,
  editor,
  toolbar
});
