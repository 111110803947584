import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateLoaderStatus } from './reducers/loader';

import 'chart.js';

import moment from 'moment';
import Select from 'components/select';
import Flatpickr from 'react-flatpickr';
import cn from 'classnames';
import { I18n, $ } from '../../utils';
import 'flatpickr/dist/themes/airbnb.css';
import 'flatpickr/dist/l10n/fi.js';
import 'animate.css/animate.min.css';
import { Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import TotalOrders from './components/total-orders';
import TotalOrderItems from './components/total-order-items';
import GeneralInfo from './components/general-info';

class Reports extends React.Component {
  groupingTypes = [
    { value: 'day', label: I18n.t('reports.charts.grouping_type.day') },
    { value: 'week', label: I18n.t('reports.charts.grouping_type.week') },
    { value: 'month', label: I18n.t('reports.charts.grouping_type.month') },
    { value: 'year', label: I18n.t('reports.charts.grouping_type.year') }
  ];

  rangePresets = {
    custom: {},
    thisMonth: {
      rangeFrom: moment().startOf('month').toDate(),
      rangeTo: moment().endOf('month').toDate()
    },
    lastMonth: {
      rangeFrom: moment().subtract(1, 'months').startOf('month').toDate(),
      rangeTo: moment().subtract(1, 'months').endOf('month').toDate()
    },
    lastThreeMonth: {
      rangeFrom: moment().subtract(3, 'months').toDate(),
      rangeTo: moment().toDate()
    },
    lastSixMonth: {
      rangeFrom: moment().subtract(6, 'months').toDate(),
      rangeTo: moment().toDate()
    },
    thisYear: {
      rangeFrom: moment().startOf('year').toDate(),
      rangeTo: moment().toDate()
    },
    lastYear: {
      rangeFrom: moment().subtract(1, 'year').startOf('year').toDate(),
      rangeTo: moment().subtract(1, 'year').endOf('year').toDate()
    }
  };

  rangePresetOptions = () => (
    Object.keys(this.rangePresets).map((preset) => (
      { value: preset, label: I18n.t(`reports.charts.grouping_presets.${preset}`) }
    ))
  );

  state = {
    groupingType: this.groupingTypes[2], // month
    rangeFrom: moment().subtract(3, 'months').toDate(),
    rangeTo: moment().toDate(),
    rangePreset: this.rangePresetOptions()[3], // 'lastThreeMonth'
    activeTab: this.props.systemReports ? 'general' : 'orders'
  };

  changePreset = ({ rangePreset }) => {
    if (rangePreset === this.state.rangePreset) { return; }
    this.props.updateLoaderStatus('LOADER_STATUS', true);

    let newState = { rangePreset: rangePreset };
    if (rangePreset !== 'custom') {
      newState = { ...newState, ...this.rangePresets[rangePreset.value] };
    }
    this.setState(newState);
  }

  changeTab = (activeTab) => {
    if (activeTab === this.state.activeTab) { return; }

    this.props.updateLoaderStatus('LOADER_STATUS', true);
    this.setState({ activeTab: activeTab });
  }

  sourceWithUrlParams = (endpoint) => {
    let dataSource = this.props.baseUrl;
    dataSource += endpoint.split('?')[0];
    dataSource += '?' + $.param(this.state);
    return dataSource;
  }
  
  render () {
    const { activeTab } = this.state;
    const navStyles = { cursor: 'pointer' };
    const { systemReports, parentReports } = this.props;
    return (
      <div>
        <div className="row d-flex align-items-center ml-2 mb-3">
          <div className="form-group col-2">
            <label>{I18n.t('reports.filter.grouping_type')}</label>
            <Select
              isSearchable={false}
              value={this.state.groupingType}
              options={this.groupingTypes}
              onChange={(groupingType) => this.setState({ groupingType })}
              isClearable={false}
            />
          </div>
          <div className="form-group col">
            <label>{I18n.t('reports.filter.presets')}</label>
            <Select
              isSearchable={false}
              value={this.state.rangePreset}
              options={this.rangePresetOptions()}
              onChange={(rangePreset) => this.changePreset({ rangePreset })}
              isClearable={false}
            />
          </div>
          <div className="form-group col-2">
            <label>{I18n.t('reports.filter.presets')}</label>
            <Flatpickr
              placeholder={I18n.t('reports.filter.from')}
              className="custom-select"
              disabled={this.state.rangePreset.value !== 'custom'}
              value={moment(this.state.rangeFrom).format('DD.MM.YY')}
              onChange={value => this.setState({ rangeFrom: value[0] })}
              options={{ dateFormat: 'd.m.y', locale: I18n.locale }}
            />
          </div>
          <div className="form-group col-2">
            <label>&nbsp;</label>
            <Flatpickr
              placeholder={I18n.t('reports.filter.to')}
              className="custom-select"
              disabled={this.state.rangePreset.value !== 'custom'}
              value={moment(this.state.rangeTo).format('DD.MM.YY')}
              onChange={(value) => this.setState({ rangeTo: value[0] })}
              options={{ dateFormat: 'd.m.y', locale: I18n.locale }}
            />
          </div>
        </div>
        <Nav tabs>
          {systemReports &&
            <NavItem style={navStyles}>
              <NavLink className={cn({ active: activeTab === 'general' })} onClick={() => { this.changeTab('general'); }}>
                Yleiset
              </NavLink>
            </NavItem>
          }
          {(systemReports || parentReports) &&
            <NavItem style={navStyles}>
              <NavLink className={cn({ active: activeTab === 'order_items' })} onClick={() => { this.changeTab('order_items'); }}>
                {parentReports ? I18n.t('reports.chart.title.order_items_by_customer') : I18n.t('person.ordered_cards')}
              </NavLink>
            </NavItem>
          }
          <NavItem style={navStyles}>
            <NavLink className={cn({ active: activeTab === 'orders' })} onClick={() => { this.changeTab('orders'); }}>
              {I18n.t('controller.title.orders')}
            </NavLink>
          </NavItem>
          {this.props.orderRequests && (
            <NavItem style={navStyles}>
              <NavLink className={cn({ active: activeTab === 'requests' })} onClick={() => { this.changeTab('requests'); }}>
                {I18n.t('controller.title.order_requests')}
              </NavLink>
            </NavItem>
          )}
          {!systemReports &&
            <NavItem style={navStyles}>
              <NavLink className={cn({ active: activeTab === 'products' })} onClick={() => { this.changeTab('products'); }}>
                {I18n.t('person.ordered_cards')}
              </NavLink>
            </NavItem>
          }
        </Nav>
        {this.props.loader &&
          <div className="text-center m-5">
            <i className="fa fa-spinner fa-spin fa-4x" />
            <br />
            <br />
            <h3>{I18n.t('reports.chart.loader')}</h3>
          </div>
        }
        <TabContent activeTab={activeTab}>
          <TabPane tabId="orders">
            <TotalOrders
              title={I18n.t('reports.chart.title.orders')}
              sourceWithUrlParams={this.sourceWithUrlParams('orders_summary')}
              active={activeTab === 'orders'}
              systemWide={systemReports || parentReports}
            />
          </TabPane>
          <TabPane tabId="requests">
            {this.props.orderRequests && (
              <TotalOrders
                title={I18n.t('reports.chart.title.order_requests')}
                sourceWithUrlParams={this.sourceWithUrlParams('order_requests_summary')}
                active={activeTab === 'requests'}
                systemWide={systemReports || parentReports}
              />
            )}
          </TabPane>
          {(systemReports || parentReports) &&
            <TabPane tabId="order_items">
              <TotalOrders
                title={I18n.t('reports.chart.title.products')}
                sourceWithUrlParams={this.sourceWithUrlParams('order_items_summary')}
                active={activeTab === 'order_items'}
                systemWide={systemReports || parentReports}
              />
            </TabPane>
          }
          {systemReports &&
            <TabPane tabId="general">
              <GeneralInfo
                sourceWithUrlParams={this.sourceWithUrlParams('general')}
                active={activeTab === 'general'}
              />
            </TabPane>
          }
          {!systemReports &&
            <TabPane tabId="products">
              <TotalOrderItems
                title={I18n.t('reports.chart.title.products')}
                sourceWithUrlParams={this.sourceWithUrlParams('products_summary')}
                active={activeTab === 'products'}
              />
            </TabPane>
          }
        </TabContent>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    loader: state.loader
  };
}

Reports.propTypes = {
  orderRequests: PropTypes.bool,
  systemReports: PropTypes.bool,
  parentReports: PropTypes.bool,
  baseUrl: PropTypes.string,
  loader: PropTypes.bool,
  updateLoaderStatus: PropTypes.func
};

export default connect(mapStateToProps, { updateLoaderStatus })(Reports);
