import React from 'react';
import PropTypes from 'prop-types';

import { errorStyles } from '../../../../utils';

class ErrorMessages extends React.Component {
  renderError () {
    if (this.props.errors && this.props.errors.length > 0) {
      return this.props.errors.map((err, index) => {
        return (
          <p style={errorStyles} key={new Date().getTime() + index} >
            <i className="fa fa-warning mr-1" />
            {err}
          </p>
        );
      });
    } else {
      return '';
    }
  }

  render () {
    return (
      <div className="form-group">
        {this.renderError()}
      </div>
    );
  }
}

ErrorMessages.propTypes = {
  errors: PropTypes.array
};

export default ErrorMessages;
