import React from 'react';
import PropTypes from 'prop-types';
import CustomAddress from '../../order_item/components/addresses/custom';
import { I18n } from '../../../utils';

const DeliveryOptions = (props) => {
  const {
    changeShippingAddress,
    children,
    countries,
    delivery,
    deliveryAddress,
    deliveryLocked,
    deliveryMessages,
    errors,
    handleDeliveryChange,
    shippingAddress
  } = props;

  return (
    <div className="well-stripe">
      <div style={{ padding: '1rem' }}>
        <h4>{I18n.t('order.delivery_method')}</h4>
        {deliveryLocked ? (
          <LockedDelivery
            delivery={delivery}
            deliveryAddress={deliveryAddress}
            deliveryMessage={deliveryMessages[delivery]}
            countryOptions={countries}
          />
        ) : (
          <>
            <OptionSelector delivery={delivery} handleDeliveryChange={handleDeliveryChange} />
            <Message message={deliveryMessages[delivery]} />
            <div>
              {delivery === 'shipping' && (
                <ShippingOption
                  shippingAddress={shippingAddress}
                  changeShippingAddress={changeShippingAddress}
                  message={deliveryMessages.shipping}
                  countryOptions={countries}
                  errors={errors}
                />
              )}
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
};

DeliveryOptions.propTypes = {
  changeShippingAddress: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  countries: PropTypes.array.isRequired,
  delivery: PropTypes.oneOf(['shipping', 'pickup']).isRequired,
  deliveryAddress: PropTypes.shape(),
  deliveryLocked: PropTypes.bool.isRequired,
  deliveryMessages: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleDeliveryChange: PropTypes.func.isRequired,
  shippingAddress: PropTypes.shape()
};

const OptionSelector = ({ delivery, handleDeliveryChange }) => (
  <div className="form-control-other">
    <span className="radio-inline">
      <label className="mr-5">
        <input
          className="form-control radio_buttons"
          type="radio"
          value="shipping"
          checked={delivery === 'shipping'}
          onChange={() => handleDeliveryChange('shipping')}
        />
        <span>
          {I18n.t('order.delivery_methods.shipping')}
        </span>
      </label>
      <label>
        <input
          className="form-control radio_buttons"
          type="radio"
          value="pickup"
          checked={delivery === 'pickup'}
          onChange={() => handleDeliveryChange('pickup')}
        />
        <span>
          {I18n.t('order.delivery_methods.pickup')}
        </span>
      </label>
    </span>
  </div>
);

OptionSelector.propTypes = {
  delivery: PropTypes.oneOf(['shipping', 'pickup']).isRequired,
  handleDeliveryChange: PropTypes.func.isRequired
};

const ShippingOption = ({ shippingAddress, changeShippingAddress, countryOptions, errors }) => (
  <div>
    <CustomAddress
      company={shippingAddress.company}
      name={shippingAddress.name}
      street={shippingAddress.street}
      zip={shippingAddress.zip}
      city={shippingAddress.city}
      country={shippingAddress.country}
      countryOptions={countryOptions}
      changeCustomAddressValue={changeShippingAddress}
      customAddressAllowed
      requireCompany
      containerStyles={{ border: 'none', padding: '0rem' }}
      {...errors}
    />
  </div>
);

ShippingOption.propTypes = {
  changeShippingAddress: PropTypes.func.isRequired,
  errors: PropTypes.shape(),
  shippingAddress: PropTypes.shape({
    company: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string
  })
};

const Message = ({ message }) => (
  !!message && message.length && <div className="info-box bg-white">{message}</div>
);

Message.propTypes = {
  message: PropTypes.string
};

const LockedDelivery = ({ delivery, deliveryAddress, deliveryMessage, countryOptions }) => (
  <>
    <div className="mb-3">{I18n.t(`order.delivery_methods.${delivery}`)}</div>
    <Message message={deliveryMessage} />
    {(delivery === 'shipping' && deliveryAddress) && (
      <div className="mb-3">
        <h4>{I18n.t('order_item.delivery_address')}</h4>
        <pre className="mb-0">{deliveryAddress.address_company}, {deliveryAddress.address_name}</pre>
        <pre className="mb-0">{deliveryAddress.address_street}</pre>
        <pre className="mb-0">{deliveryAddress.address_zip}, {deliveryAddress.address_city}</pre>
        <pre className="mb-0">{countryOptions.find((c) => c[1] === (deliveryAddress.address_country || 'FI'))[0]}</pre>
      </div>
    )}
  </>
);

LockedDelivery.propTypes = {
  delivery: PropTypes.oneOf(['shipping', 'pickup']).isRequired,
  deliveryAddress: PropTypes.shape({
    address_company: PropTypes.string,
    address_name: PropTypes.string,
    address_street: PropTypes.string,
    address_zip: PropTypes.string,
    address_city: PropTypes.string
  }),
  deliveryMessage: PropTypes.string
};

export default DeliveryOptions;
