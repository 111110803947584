import { ACTIVATE_FIELD, LAYOUT_POST, LAYOUT_POST_SUCCESS, LAYOUT_POST_FAILED, CARD_SIDE_CHANGE,
  INITIAL_SETUP_COMPLETE } from '../actions/designer';
import { UPLOAD_BACKGROUND_REQUEST, UPLOAD_BACKGROUND_SUCCESS, UPLOAD_BACKGROUND_FAIL } from '../actions/toolbar';

const initialState = {
  cardId: null,
  userId: null,
  layoutId: null,
  lastSaved: null,
  activeFieldId: null,
  activeCardSide: 'frontSide',
  loading: false
};

export default function (state, action) {
  state = Object.assign({}, initialState, state);

  switch (action.type) {
    case ACTIVATE_FIELD:
      return Object.assign({}, state, {
        activeFieldId: action.fieldId ? action.fieldId.toString() : null
      });
    case LAYOUT_POST_SUCCESS:
      return Object.assign({}, state, {
        lastSaved: action.payload.lastSaved,
        loading: action.loading
      });
    case LAYOUT_POST:
    case LAYOUT_POST_FAILED:
    case UPLOAD_BACKGROUND_REQUEST:
    case UPLOAD_BACKGROUND_FAIL:
      return Object.assign({}, state, {
        loading: action.loading
      });
    case UPLOAD_BACKGROUND_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        isNew: false
      });
    case CARD_SIDE_CHANGE:
      return Object.assign({}, state, {
        activeCardSide: action.cardSide
      });
    case INITIAL_SETUP_COMPLETE:
      let cardInfo = Object.assign({}, state.cardInfo);
      cardInfo['aspectRatioX'] = action.aspectRatioX;
      cardInfo['aspectRatioY'] = action.aspectRatioY;

      return Object.assign({}, state, {
        cardInfo
      });
    default:
      return state;
  }
}
