import React from 'react';
import PropTypes from 'prop-types';

const OrderLine = ({ line }) => {
  return (
    <tr>
      <td>{line.name}</td>
      <td className="text-right">{parseFloat(line.price).toFixed(2)} €</td>
      <td className="text-right">{line.vat} %</td>
      <td className="text-right">{line.pieces}</td>
      <td className="text-right">{parseFloat(line.products_price).toFixed(2)} €</td>
      <td className="text-right">{parseFloat(line.vat_total).toFixed(2)} €</td>
      <td className="text-right font-weight-bold">{parseFloat(line.total_price).toFixed(2)}</td>
    </tr>
  );
};

OrderLine.propTypes = {
  line: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    vat: PropTypes.number.isRequired,
    pieces: PropTypes.number.isRequired,
    products_price: PropTypes.number.isRequired,
    vat_total: PropTypes.number.isRequired,
    total_price: PropTypes.number.isRequired
  }).isRequired
};

export default OrderLine;
