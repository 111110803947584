import React from 'react';
import PropTypes from 'prop-types';
import request from '../order_item/request';
import { I18n } from '../../utils';
import { Progress } from 'reactstrap';

class DownloadAllCards extends React.Component {
  state = {
    downloadable: this.props.downloadable,
    done: this.props.done || 0,
    total: this.props.total || 0
  };

  ORDER_URL = window.location.origin + '/api/orders/' + this.props.orderId;

  componentDidMount () {
    if (!this.props.downloadable) {
      this.checkStatus();
    }
  }

  checkStatus = () => {
    let pingTime = 1000;
    const url = this.ORDER_URL + '/designed_cards_generation_progress';

    setTimeout(() => {
      request.get(url).done((data) => {
        let body = JSON.parse(data.body);

        this.setState({ ...body });
        if (!body.downloadable && !this.state.error) {
          this.checkStatus();
        }
      });
    }, pingTime);
  }

  renderDownloadLink () {
    let dlUrl = this.ORDER_URL + '/download_designed_cards_zip';

    return (
      <div className="info-box flex-column">
        <div className="w-100">
          {I18n.t('customer.action.order.pdf_cards_generated')} <a href={dlUrl} download>{I18n.t('customer.action.order.download_all_cards')} <i className="fa fa-file-archive-o" /></a>
        </div>
      </div>
    );
  }

  renderProgressIndicator () {
    let { done, total } = this.state;
    
    if (this.state.error) {
      return (
        <div className="mt-5 ml-3 info-box info-box-danger w-75" >
          {I18n.t('order.file_import.errors.general_error')}
        </div>
      )
    }
    return (
      <div className="info-box flex-column">
        <div className="w-100">
          {I18n.t('customer.action.order.generating_pdf_cards')}
        </div>
        <div className="w-100">
          <Progress animated value={done} max={total} className="progress-percent align-items-center">
            <div className="fa-stack hourglass-spin align-self-center">
              <i className="fa fa-stack-1x fa-hourglass-start" />
              <i className="fa fa-stack-1x fa-hourglass-half" />
              <i className="fa fa-stack-1x fa-hourglass-end" />
              <i className="fa fa-stack-1x fa-hourglass-end spin" />
            </div>
          </Progress>
        </div>
      </div>
    );
  }

  render () {
    return this.state.downloadable ? this.renderDownloadLink() : this.renderProgressIndicator();
  }
}

DownloadAllCards.propTypes = {
  orderId: PropTypes.number.isRequired,
  done: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  downloadable: PropTypes.bool.isRequired
};

export default DownloadAllCards;
