import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateFieldAttribute, updateCoordinates } from '../actions/canvas';
import TextExample from '../components/toolbar/text_example';
import FontFamily from '../components/toolbar/font_family';
import FontSize from '../components/toolbar/font_size';
import ColorPicker from '../components/toolbar/color_picker';
import BackgroundUpload from './toolbar/background_upload';
import FieldReset from '../components/toolbar/field_reset';
import SelectType from '../components/toolbar/select_type';
import FontStyle from '../components/toolbar/font_style';
import TextAlign from '../components/toolbar/text_align';
import TextRotate from '../components/toolbar/text_rotate';
import CoordinateSettings from '../components/toolbar/coordinate_settings';

import { I18n } from 'utils';

class Toolbar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      modalOpen: false,
      field: this.props.field
    };

    this.updateFieldAttribute = this.updateFieldAttribute.bind(this);
    this.resetFieldCoordinates = this.resetFieldCoordinates.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  showModal (state = true) {
    this.setState({ modalOpen: state });
  }

  handleClick () {
    this.showModal();
  }

  degreesToRadians (degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }

  rotatePoint = ({ x, y }, rad) => {
    const rcos = Math.cos(rad);
    const rsin = Math.sin(rad);
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
  };
  
  // will work for shapes with top-left origin, like rectangle
  rotateAroundCenter (rotation) {
    // current rotation origin (0, 0) relative to desired origin - center (node.width()/2, node.height()/2)
    let { field, activeCardSide } = this.props;
    const topLeft = { x: -field.width / 2, y: -field.height / 2 };
    const current = this.rotatePoint(topLeft, this.degreesToRadians(field.rotation || 0));
    const rotated = this.rotatePoint(topLeft, this.degreesToRadians(rotation));
    const dx = rotated.x - current.x;
    const dy = rotated.y - current.y;

    this.props.updateCoordinates(activeCardSide, field.id, field.x + dx, field.y + dy, field.width, field.height);
  }

  updateFieldAttribute (attribute, value) {
    let { field, activeCardSide } = this.props;
    if (attribute === 'rotation') {
      this.rotateAroundCenter(value);
    }
    this.props.updateFieldAttribute(activeCardSide, field.id, attribute, value);
    if (attribute === 'fontSize') {
      this.props.updateCoordinates(activeCardSide, field.id, field.x, field.y, field.width, value);
    }
    if (attribute === 'fieldType' && value === 'code_qr') {
      this.props.updateCoordinates(activeCardSide, field.id, field.x, field.y, 50, 50);
      this.props.updateFieldAttribute(activeCardSide, field.id, 'aspectRatioX', 1);
      this.props.updateFieldAttribute(activeCardSide, field.id, 'aspectRatioY', 1);
    }
    if (attribute === 'fieldType' && value === 'string') {
      this.resetFieldCoordinates();
    } else if (attribute === 'fieldType') {
      let { field, activeCardSide } = this.props;
      this.props.updateCoordinates(activeCardSide, field.id, 20, 20, 400, 100);
      this.props.updateFieldAttribute(activeCardSide, field.id, 'rotation', 0);
    }
  }

  resetFieldCoordinates () {
    let { field, activeCardSide } = this.props;
    this.props.updateCoordinates(activeCardSide, field.id, 20, 20);
  }

  renderToolbarInputs () {
    let { field } = this.props;
    if (field) {
      return (
        <div className="col">
          <div className="d-flex w-100">
            {field.fieldType === 'string' && <TextExample field={field} updateFieldAttribute={this.updateFieldAttribute} />}
            {field.fieldType === 'string' && <FieldReset resetCoordinates={this.resetFieldCoordinates} />}
          </div>
          <div className="d-flex w-100">
            {field.fieldType === 'string' && <FontFamily value={field.fontFamily} updateFieldAttribute={this.updateFieldAttribute} fonts={this.props.fonts} />}
            {field.fieldType === 'string' && <FontSize value={field.fontSize} updateFieldAttribute={this.updateFieldAttribute} />}
            {field.fieldType === 'string' && <ColorPicker value={field.fillColor} updateFieldAttribute={this.updateFieldAttribute} />}
            {field.fieldType === 'string' && <FontStyle value={field.fontStyle} updateFieldAttribute={this.updateFieldAttribute} />}
            {field.fieldType === 'string' && <TextAlign value={field.align} updateFieldAttribute={this.updateFieldAttribute} />}
          </div>
          <div className="row d-flex">
            <CoordinateSettings field={field} updateFieldAttribute={this.updateFieldAttribute} />
            {field.fieldType === 'string' && <TextRotate value={Number(field.rotation)} updateFieldAttribute={this.updateFieldAttribute} />}
          </div>
        </div>);
    }
    return false;
  }
  
  fieldName = () => {
    let { field } = this.props;
    if (field && field.name === 'fixed_field') {
      return I18n.t('card_layout.fixed_field');
    }
    return field ? field.name : I18n.t('card_layout.toolbar.no_selection');
  }

  render () {
    let { field } = this.props;
    
    return (
      <div>
        <div className="border mb-2 pt-1 d-flex">
          <div className="col-2 border-right">
            <div>
              <small className="text-muted text-uppercase">{I18n.t('card_layout.background.title')}</small>
              <button
                className="btn btn-primary btn-sm form-control form-control-sm border-0"
                onClick={this.handleClick}
                style={{ 'cursor': 'pointer', 'padding': '0.3rem' }}
              >
                {I18n.t('general.action.change')}
              </button>
            </div>
            <div style={{ height: '76px' }} />
            {/*
            <div className="mb-3">
            <small className="text-muted text-uppercase">{I18n.t('card_layout.aspect_ratio')}</small>
              <button className="btn btn-primary btn-sm form-control form-control-sm border-0" style={{ 'cursor': 'pointer', 'padding': '0.3rem' }}>
                Placeholder
              </button>
            </div>
            */}
          </div>
          <div className="form-group col-2">
            <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.field')} </small>
            <span className="form-control form-control-sm border-0">{this.fieldName()}</span>
            {(field && ['string', 'code_128', 'code_39', 'code_39e', 'code_qr'].includes(field.fieldType)) && <SelectType value={field.fieldType} updateFieldAttribute={this.updateFieldAttribute} />}
          </div>
          {this.renderToolbarInputs()}
        </div>
        <BackgroundUpload modalOpen={this.state.modalOpen} showModal={this.showModal} />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    field: state.canvas[state.designer.activeCardSide].fields[state.designer.activeFieldId],
    fonts: state.designer.fonts,
    activeCardSide: state.designer.activeCardSide
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    updateFieldAttribute,
    updateCoordinates
  }, dispatch);
}

Toolbar.propTypes = {
  field: PropTypes.shape(),
  updateFieldAttribute: PropTypes.func,
  updateCoordinates: PropTypes.func,
  activeCardSide: PropTypes.string.isRequired,
  fonts: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
