import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/select';
import { I18n } from 'utils';

class SelectType extends React.Component {
  handleOnChange = ({ value, _label }) => {
    this.props.updateFieldAttribute('fieldType', value);
  }
  
  render () {
    const options = [
      { value: 'string', label: I18n.t('field.field_type.string') },
      { value: 'code_128', label: 'Code 128' },
      { value: 'code_39', label: 'Code 39' },
      { value: 'code_39e', label: 'Code 39 Ext.' },
      { value: 'code_qr', label: 'Code QR' }
    ];
    return (
      <div className="mb-0 form-group w-100" style={{ fontSize: '0.875rem' }}>
        <small className="text-uppercase text-muted">{I18n.t('activerecord.attributes.field.field_type')}</small>
        <Select
          options={options}
          value={options.filter((option) => option.value === this.props.value)}
          onChange={this.handleOnChange}
          isClearable={false}
          isSearchable={false}
        />
      </div>
    );
  }
}

SelectType.propTypes = {
  value: PropTypes.string,
  updateFieldAttribute: PropTypes.func
};

export default SelectType;
