import React from 'react';
import { Image } from 'react-konva';
import PropTypes from 'prop-types';

class CardImage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      image: null
    };
  }

  componentDidMount () {
    this.setImage(this.props);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setImage(nextProps);
  }

  setImage (props) {
    const image = new window.Image();
    image.src = props.src;
    image.onload = () => {
      this.setState({
        image: image
      }, () => {
        this.photoRef.cache();
      });
    };
  }

  calcHeight () {
    if (this.props.backgroundImage) {
      // Dont force background to any aspect ratio
      return null;
    } else if (['code_128', 'code_39', 'code_39e'].includes(this.props.fieldType)) {
      return this.props.height;
    } else {
      return this.props.width / (this.props.aspectRatioX / this.props.aspectRatioY);
    }
  }

  render () {
    let { id, ...other } = this.props;
    id = this.props.id && this.props.id.toString();
    return (
      <Image
        {...other}
        image={this.state.image}
        name={id}
        draggable={!this.props.backgroundImage && this.props.draggable}
        scale={{ x: 1, y: 1 }}
        height={this.calcHeight()}
        ref={(node) => { this.photoRef = node; }}
      />
    );
  }
}

CardImage.propTypes = {
  backgroundImage: PropTypes.bool,
  draggable: PropTypes.bool,
  uvSide: PropTypes.bool,
  src: PropTypes.string,
  aspectRatioX: PropTypes.number,
  aspectRatioY: PropTypes.number,
  width: PropTypes.number,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default CardImage;
