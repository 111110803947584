/** 
* Wrapper library around then-request. Adds a json parsed body to each 
* response, accessible at response.parsedBody.
**/
import request from 'then-request';
var endpoint = '';

/**
* Perform the actual xmlhttp request, parse response body
**/
function performRequest (method, url, options) {
  options.headers = options.headers || {};
  return request(method, urlWithEndpoint(url), options).then((resp) => {
    try {
      resp.parsedBody = JSON.parse(resp.body.toString());
    } catch (e) {
      resp.parsedBody = undefined;
    }
    return resp;
  });
}

function urlWithEndpoint (url) {
  return endpoint + url;
}

const req = {
  post: function (url, body, options = {}) {
    options.json = body;
    return performRequest('POST', url, options);
  },

  get: function (url, options = {}) {
    return performRequest('GET', url, options);
  },

  put: function (url, body, options = {}) {
    options.json = body;
    return performRequest('PUT', url, options);
  },

  delete: function (url, body, options = {}) {
    options.json = body;
    return performRequest('DELETE', url, options);
  },

  changeEndpoint (newEndpoint) {
    endpoint = newEndpoint;
    return endpoint;
  },
  
  postFormData: function (url, formData) {
    return performRequest('POST', url, { form: formData });
  }
};

export default req;
