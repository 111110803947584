import { $ } from './utils';

function setRuleInfo () {
  let selected = $(this).val();
  let typeInfo = $(this).data('infos');
  let selectedType = typeInfo[selected];
  const showPopover = ['CustomDate'];

  let typeInput = $(this).closest('.row').find('input[type="text"]');
  let notEditable = typeInput.hasClass('not-editable');

  typeInput.prop('disabled', selectedType.predefined);

  let pholder;
  if (typeInput.is(':disabled')) {
    pholder = selectedType.placeholder == null ? '' : selectedType.placeholder;
    typeInput.val('');
  } else {
    pholder = '';
  }
  typeInput.prop('placeholder', pholder);

  if (notEditable) {
    typeInput.prop('disabled', true);
  }
  
  // show popover if it's included in the list
  let popOver = $(this).closest('.row').find('#custom_date_popover');
  popOver.toggle(showPopover.includes(selected));
}
function setFieldTypeInfo () {
  // Hide all visible popover icons for field
  $(this).closest('.row').find('.field-type-popover').hide();
  // Close all open popovers in page
  $('[data-toggle="popover"]').popover('hide');

  let selectedType = $(this).val();
  const showInfoPopover = [
    'combined',
    'expiration',
    'feedback',
    'list',
    'card_specific_running_number'
  ];
  // Find type specific popover icon
  let popOverIcon = $(this).closest('.row').find(`#${selectedType}_popover`);
  // Show popover icon if allowed
  popOverIcon.toggle(showInfoPopover.includes(selectedType));
}

function fieldOptions () {
  const needsOptions = ['list'];
  const rulesForbidden = ['feedback', 'boolean'];
  const modifierRules = ['combined'];

  let optionsInput = $(this).closest('.row').find('textarea');
  let notEditable = optionsInput.hasClass('not-editable');
  optionsInput.prop('disabled', !needsOptions.includes($(this).val()));

  if (optionsInput.is(':disabled')) {
    optionsInput.val('');
    optionsInput.parent().parent().hide();
  } else {
    optionsInput.parent().parent().show();
  }

  if (notEditable) {
    optionsInput.prop('disabled', true);
  }

  let cardElement = $(this).closest('.card-body');
  let validationSelect = cardElement.find('.validation select.rules');
  let modifierSelect = cardElement.find('.modifier select.rules');

  if (modifierRules.includes($(this).val())) {
    if (validationSelect.is(':enabled')) {
      modifierSelect.prop('disabled', false);
    }
    validationSelect.prop('disabled', true);
    cardElement.find('.validation').hide();
    cardElement.find('.modifier').show();
  } else {
    if (modifierSelect.is(':enabled')) {
      validationSelect.prop('disabled', false);
    }
    modifierSelect.prop('disabled', true);
    cardElement.find('.modifier').hide();
    cardElement.find('.validation').show();
  }

  let validationRules = cardElement.find('div.validation-rules');

  if (rulesForbidden.includes($(this).val())) {
    validationRules.hide();
  } else {
    validationRules.show();
  }
  triggerRuleInfoChange(validationRules);
}

function triggerRuleInfoChange (validationRulesElem) {
  validationRulesElem.find('select.rules').trigger('change');
}

export default function init () {
  $('.field-type-select').on('change', fieldOptions);
  $('.field-type-select').each(fieldOptions);
  $('.field-type-select').on('change', setFieldTypeInfo);
  $('.field-type-select').each(setFieldTypeInfo);
  $('.rules').on('change', setRuleInfo);
  $('.rules:visible').each(setRuleInfo);

  $('form').on('cocoon:after-insert', function (e, insertedItem) {
    $('.field-type-select').on('change', fieldOptions);
    $('.field-type-select').each(fieldOptions);
    $('.field-type-select').on('change', setFieldTypeInfo);
    $('.field-type-select').each(setFieldTypeInfo);
    $('.rules').on('change', setRuleInfo);
    $('.rules:visible').each(setRuleInfo);
    insertedItem.find('*').filter(':input:visible:first').focus();
    $('[data-toggle="popover"]').popover();
  });

  $('form').on('cocoon:before-insert', function (e, item) {
    item.fadeIn(300);
  });

  $('form').on('cocoon:before-remove', function (e, item) {
    item.fadeOut(300);
  });
}
