import FlatpickrI18n from 'flatpickr/dist/l10n/fi.js';
import { I18n as InitI18n } from 'i18n-js';
import translations from './translations.json';

export const $ = window.$;
export const FileReader = window.FileReader;
export const localStorage = window.localStorage;
const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
export const csrfToken = csrfTokenElement ? csrfTokenElement.content : null;

export const I18n = new InitI18n(translations);
I18n.defaultLocale = 'fi';
window.I18n = I18n;

export function initSelect2 () {
  $('.select[multiple="multiple"], .select2').select2({
    theme: 'bootstrap',
    dropdownAutoWidth: true,
    width: 'auto'
  });
}

export function setLocale () {
  let currentLang = $('html').attr('lang');
  if (I18n) {
    I18n.locale = currentLang;
  }
}

export const errorStyles = {
  borderColor: '#D0011B',
  color: '#D0011B'
};

export function cocoon (displayScope) {
  $('form')
    .on('cocoon:after-insert', function (e, insertedItem) {
      initSelect2();
      insertedItem.find('*').filter(':input:visible:first').focus();
      if ($(this).data('sortable')) {
        setDisplayOrder();
      }
      $('[data-toggle="tooltip"]').tooltip();
    })
    .on('cocoon:after-remove', function (e, item) {
      if ($(this).data('sortable')) {
        setDisplayOrder();
      }
    })
    .on('cocoon:before-insert', function (e, item) {
      item.fadeIn(300);
    })
    .on('cocoon:before-remove', function (e, item) {
      item.fadeOut(300);
    });
}

export const setDisplayOrder = () => {
  $('.nested-fields').each((index, elem) => {
    $(elem).find('.display_order').val(index);
  });
};

export const datepicker = () => {
  const language = I18n.locale === 'fi' ? {locale: FlatpickrI18n.fi, altFormat: 'd.m.y', altInput: true, altInputClass: 'flatpickr p-1 col-5'} : {}
  $('.flatpickr').flatpickr(language)
};
