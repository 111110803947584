import request from '../order_item/request';
let subscriptions = {};
let timerId = null;
let url = window.location.origin + '/api/order_items/designed_card_generation_statuses';

export const fetchStatuses = (orderRequest, id, callback) => {
  if (orderRequest) {
    url = window.location.origin + '/api/order_requests/designed_card_generation_statuses';
  }
  subscriptions[id] = callback;
  clearTimeout(timerId);
  timerId = queryStatuses();
};

const queryStatuses = () => {
  return setTimeout(() => {
    let orderItemIds = Object.keys(subscriptions);

    request.post(url, { orderItemIds: orderItemIds }).done((data) => {
      callbackOrderItems(JSON.parse(data.body));
      if (Object.keys(subscriptions).length > 0) {
        queryStatuses();
      }
    });
  }, 2000);
};

const callbackOrderItems = (orderItems) => {
  for (let orderItem of orderItems) {
    if (orderItem.downloadable) {
      subscriptions[orderItem.id]('downloadable');
      delete (subscriptions[orderItem.id]);
    } else if (orderItem.errors) {
      subscriptions[orderItem.id]({errors: orderItem.errors});
      delete (subscriptions[orderItem.id]);
    }
  }
};
