import { ADD_CANVAS_FIELD, REMOVE_CANVAS_FIELD } from '../actions/editor';
import { UPDATE_FIELD_COORDINATES, UPDATE_FIELD_ATTRIBUTE } from '../actions/canvas';
import { UPLOAD_BACKGROUND_SUCCESS } from '../actions/toolbar';
import { LAYOUT_POST_SUCCESS } from '../actions/designer';

const initialState = {};

export default function (state = initialState, action) {
  state = Object.assign({}, initialState, state);
  let side = Object.assign({}, state[action.cardSide]);
  let fields = Object.assign({}, side.fields);
  switch (action.type) {
    case ADD_CANVAS_FIELD:
      if (Object.keys(fields).includes(action.field.id.toString()) && (typeof action.fieldId === 'number' || action.fieldId === 'photo')) {
        let singleField1 = Object.assign({}, fields[action.field.id], {
          deleted: false
        });
        fields[action.field.id] = singleField1;
      } else {
        fields[action.field.id] = action.field;
      }
      side.fields = fields;
      return Object.assign({}, state, {
        [action.cardSide]: side
      });
    case REMOVE_CANVAS_FIELD:
      if (typeof action.fieldId === 'string' && action.fieldId.slice(0, 11) === 'fixed_field' && action.fieldId.length < 14) {
        delete fields[action.fieldId];
      } else {
        let singleField = Object.assign({}, fields[action.fieldId], {
          deleted: true
        });
        fields[action.fieldId] = singleField;
      }
      side.fields = fields;
      return Object.assign({}, state, {
        [action.cardSide]: side
      });
    case UPDATE_FIELD_COORDINATES:
      let updatedField = Object.assign({}, fields[action.fieldId], {
        x: action.x,
        y: action.y,
        width: action.width,
        height: action.height
      });
      fields[action.fieldId] = updatedField;
      side.fields = fields;
      return Object.assign({}, state, {
        [action.cardSide]: side
      });
    case UPDATE_FIELD_ATTRIBUTE:
      let field = Object.assign({}, fields[action.fieldId]);
      field[action.attribute] = action.value;
      fields[action.fieldId] = field;
      side.fields = fields;
      return Object.assign({}, state, {
        [action.cardSide]: side
      });
    case UPLOAD_BACKGROUND_SUCCESS:
      let newSide = Object.assign({}, side, action.payload);
      return Object.assign({}, state, {
        [action.cardSide]: newSide
      });
    case LAYOUT_POST_SUCCESS:
      let newState = Object.assign({}, action.payload.fields);
      return Object.assign({}, newState);
    default:
      return state;
  }
}
