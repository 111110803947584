import loaderReducer from './reducers/loader.js';
import thunkMiddleware from 'redux-thunk'; // For handling async actionCreators.
import { createStore, applyMiddleware } from 'redux';

const store = createStore(
  loaderReducer,
  applyMiddleware(thunkMiddleware),
);

export default store;
