import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import store from './stores/designer';
import Designer from './containers/designer';

import 'animate.css/animate.min.css';

class App extends React.Component {
  render () {
    const initialStore = {
      canvas: this.props.canvas,
      designer: this.props.designer,
      editor: this.props.editor
    };

    return (
      <Provider store={store(initialStore)}>
        <Designer />
      </Provider>
    );
  }
}

App.propTypes = {
  canvas: PropTypes.shape({
    src: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number
  }),
  designer: PropTypes.shape(),
  editor: PropTypes.shape()
};

export default App;
