/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import WebpackerReact from 'webpacker-react';
import CardImages from '../apps/product_photo/card_images';
import ReportsApp from '../apps/reports/app';
import DesignerApp from '../apps/designer/app';
import OrderRequestApp from '../apps/order_request/app';
import DownloadIndicator from '../apps/order/order_item/designed_card_indicator';
import DownloadAllCards from '../apps/order/download_all_cards';
import Checkout from '../apps/webshop/checkout';

import cardFields from '../card_fields';
import productForm from '../product_form';
import { initSelect2, setLocale, $, cocoon, I18n, datepicker } from '../utils';
import moment from 'moment';

require('chartkick');
require('chart.js');
setLocale();
WebpackerReact.setup({ ReportsApp });

WebpackerReact.setup({ CardImages });
WebpackerReact.setup({ DesignerApp });
WebpackerReact.setup({ OrderRequestApp });
WebpackerReact.setup({ DownloadIndicator });
WebpackerReact.setup({ DownloadAllCards });
WebpackerReact.setup({ 'WebshopCheckout': Checkout });

$(document).ready(function () {
  setLocale();
  initSelect2();
  cocoon();
  productForm();
  cardFields();
  datepicker();
  
  $('.dropdown-toggle').dropdown();

  // Auto-submit filter when select changes
  $('form.form-filter, form[data-submit-on-change]').on('change', 'select', function () {
    $(this).closest('form').submit();
  });

  $('.wants-tip').tooltip({html: true, container: 'body', animation: false, placement: 'bottom', delay: 300});

  $('a.btn-loader').on('click', function () {
    if (!$(this).hasClass('disabled')) {
      $(this).addClass('disabled');
      $(this).append(" <i class='fa fa-spinner fa-spin'>");
    }
  });

  $('input.btn-loader').each((i, elem) => {
    $(elem).attr('data-disable-with', $(elem).val() + '...');
  });
  
  $('button.btn-spinner').each((i, elem) => {
    let buttonText = $(elem).text() + " <i class='fa fa-circle-o-notch fa-spin'></i>";
    $(elem).attr('data-disable-with', buttonText);
  });

  $('#user_select.select2').select2({
    theme: 'bootstrap',
    closeOnSelect: true,
    tags: true,
    placeholder: I18n.t('user.email_select_placeholder')
  });

  $('.custom-file-input').on('change', function () {
    let name = $(this).val().match(/[^\\/]*$/)[0];
    $(this).next('.form-control-file').addClass('selected').html(name);
  });
  
  $('[data-toggle="popover"]').popover();
  $('.message[data-toggle="tooltip"]').tooltip({
    animation: true,
    delay: {
      show: 300, hide: 0
    }
  });
  $('[data-toggle="tooltip"]').tooltip();
  $('table.use-sorter').tablesorter();
  
  $.tablesorter.addParser({
    id: 'dateTime',
    is: function (s) {
      // return false so this parser is not auto detected
      return false;
    },
    format: function (s) {
      if (!s.length) {
        return new Date();
      } else if (I18n.locale === 'fi') {
        return moment(s, 'DD.MM.YYYY hh:mm').toDate();
      } else {
        return moment(s).toDate();
      }
    },
    // set type, either numeric or text
    type: 'numeric'
  });
  
  $('table.use-orders-sorter').tablesorter({
    headers: {
      0: {
        sorter: 'dateTime'
      },
      4: {
        sorter: 'dateTime'
      }
    }
  });
  
  $('table.use-admin-orders-sorter').tablesorter({
    headers: {
      1: {
        sorter: 'dateTime'
      },
      5: {
        sorter: 'dateTime'
      }
    }
  });
  
  $('table.use-order-request-sorter').tablesorter({
    headers: {
      0: {
        sorter: 'dateTime'
      },
      5: {
        sorter: 'dateTime'
      }
    }
  });

  $('.file-import:file').filestyle({
    iconName: 'fa fa-folder-open',
    buttonText: 'Valitse tiedosto',
    buttonName: 'btn-default btn-file-upload',
    buttonBefore: false
  });

  $('#tos_accepted').on('change', function () {
    $('#submit_registration').attr('disabled', !$(this).prop('checked'));
  });
});
