import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '../../../utils';
import { fetchStatuses } from '../utils';

class DownloadIndicator extends React.Component {
  state = {
    downloadable: this.props.downloadable,
    iconSize: this.props.iconSize || '',
    dlUrl: this.props.apiUrl
  };

  componentDidMount () {
    if (!this.state.downloadable) {
      this.checkStatus();
    }
  }

  checkStatus = () => {
    fetchStatuses(this.props.orderRequest, this.props.orderItemId, (downloadable) => {
      if (downloadable === 'downloadable') {
        this.setState({ downloadable });
      } else if (downloadable.errors) {
        this.setState({ errors: downloadable.errors });
      }
    });
  }
  
  orderRequestTitle = () => {
    if (this.props.orderRequest) {
      if (this.state.downloadable) {
        return <sup style={{fontSize: '1.2em', paddingRight: '1em'}}>{I18n.t('customer.action.order_request.pdf_download')}</sup>;
      }
      return <sup style={{fontSize: '1.2em', paddingRight: '1em'}}>{I18n.t('customer.action.order_request.generating_pdf')}</sup>;
    }
    return '';
  }

  renderDownloadLink () {
    return (
      <span>
        {this.orderRequestTitle()}
        <a href={this.state.dlUrl} download>
          <i className={`fa fa-file-pdf-o ${this.state.iconSize}`} />
        </a>
      </span>
    );
  }
  
  renderProgressIndicator () {
    if (this.state.errors) {
      return this.renderErrors()
    }
    return (
      <span>
        {this.orderRequestTitle()}
        <i className={`fa fa-circle-o-notch fa-spin fa-fw ${this.state.iconSize}`} />
      </span>
    );
  }
  
  renderErrors () {
    return (
      <span>
        {!this.props.orderRequest && <i className="fa fa-times mr-1" style={{color: 'red'}} /> }
        {I18n.t('customer.action.order_request.generating_error')}
      </span>
    );
  }
  
  renderSelector () {
    return this.state.downloadable ? this.renderDownloadLink() : this.renderProgressIndicator();
  }

  render () {
    return this.props.orderRequest
      ? <div className={`mt-5 ml-3 info-box ${this.state.errors ? 'info-box-danger' : 'info-box'} w-75`}>
        {this.renderSelector()}
      </div>
      : this.renderSelector();
  }
}

DownloadIndicator.propTypes = {
  orderItemId: PropTypes.number.isRequired,
  downloadable: PropTypes.bool.isRequired,
  apiUrl: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  orderRequest: PropTypes.bool
};

export default DownloadIndicator;
