import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'utils';

const CustomerOrdersTable = ({ totals, customerOrders }) => (
  <table className="table table-hover">
    <thead>
      <tr>
        <th>{I18n.t('activerecord.models.customer')}</th>
        <th className="text-center">{I18n.t('reports.general.total')}</th>
      </tr>
    </thead>
    <tbody>
      {customerOrders.map((customer) => (
        <tr id={customer.customer} key={customer.customer}>
          <td>
            {customer.userAccess ? <a href={customer.reportsUrl}>{customer.customer}</a> :
              customer.customer}
          </td>
          <td className="text-center">{customer.orders}</td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        <th>{I18n.t('order.stats.total')}</th>
        <td className="text-center">
          {customerOrders.reduce((acc, currentVal) => (
            acc + currentVal.orders
          ), 0)}
        </td>
      </tr>
    </tfoot>
  </table>
);

CustomerOrdersTable.propTypes = {
  customerOrders: PropTypes.array.isRequired,
  totals: PropTypes.shape().isRequired
};

export default CustomerOrdersTable;
