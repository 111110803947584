import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'utils';

class TextExample extends React.Component {
  constructor (props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentDidMount () {
    this.textInput.focus();
    this.textInput.select();
  }

  handleOnChange (e) {
    this.props.updateFieldAttribute('text', e.target.value);
  }

  handleOnBlur (e) {
    if (e.target.value === '') {
      this.props.updateFieldAttribute('text', I18n.t('card_layout.toolbar.field_example_text'));
    }
  }
  
  title = () => {
    const field = this.props.field;
    if (field.name === 'fixed_field') {
      return I18n.t('card_layout.fixed_fields.add_text');
    }
    switch (field.fieldType) {
      case 'string':
        return I18n.t('card_layout.toolbar.field_example_text');
      case 'code_39':
      case 'code_39e':
      case 'code_128':
      case 'code_qr':
        return I18n.t('field.barcode.info');
      default:
        return '';
    }
  }

  render () {
    return (
      <div className="mr-2 mb-0 form-group">
        <small className="text-uppercase text-muted">{this.title()}</small>
        <input
          type="text"
          value={this.props.field.text}
          className="form-control form-control-sm"
          onBlur={this.handleOnBlur}
          style={{ width: '250px' }}
          ref={c => (this.textInput = c)}
          onChange={this.handleOnChange} />
      </div>
    );
  }
}

TextExample.propTypes = {
  updateFieldAttribute: PropTypes.func,
  field: PropTypes.object
};

export default TextExample;
