import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Card, CardBody, CardTitle } from 'reactstrap';

import { saveLayout, initialSetup } from '../actions/designer';
import Canvas from './canvas';
import Editor from './editor';
import Toolbar from './toolbar';
import InitialSettings from './designer/initial_settings';
import { I18n } from 'utils';

class Designer extends React.Component {
  constructor (props) {
    super(props);
    this.state = { scale: 0.60 };

    this.increaseScaling = this.increaseScaling.bind(this);
    this.decreaseScaling = this.decreaseScaling.bind(this);
    this.setScale = this.setScale.bind(this);
    this.completeInitialSetup = this.completeInitialSetup.bind(this);
  }

  completeInitialSetup (aspectRatioX, aspectRatioY) {
    this.props.saveLayout();
    this.props.initialSetup(aspectRatioX, aspectRatioY);
  }

  increaseScaling () {
    this.setState({
      scale: this.state.scale + 0.01
    });
  }

  decreaseScaling () {
    this.setState({
      scale: this.state.scale - 0.01
    });
  }

  setScale (e) {
    let value = parseFloat(e.target.value);
    this.setState({
      scale: value
    });
  }

  renderSaveButton () {
    return (
      <div className="float-right">
        <button type="button" className="btn btn-primary btn-block" onClick={this.props.saveLayout}>
          <div className="d-flex justify-content-center">
            {I18n.t('action.save')}
            {this.props.loading && <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
          </div>
        </button>
        <div>
          <small className="text-muted text-lowercase">{I18n.t('card_layout.designed.last_saved')} {this.props.lastSaved}</small>
        </div>
      </div>
    );
  }

  renderDesigner () {
    let btnStyles = {
      'padding': '1px 5px'
    };
    return (
      <Card>
        <CardBody>
          <CardTitle>
            {this.props.cardInfo.name}
          </CardTitle>
          <div className="row">
            <div className="col">
              <Toolbar />
            </div>
          </div>
          <div className="row">
            <div className="col-9" style={{ minHeight: 500 }}>
              <div className="mb-2 d-flex justify-content-center">
                <Button onClick={this.decreaseScaling} style={btnStyles}>-</Button>
                <input type="range" min={0.2} max={2} step={0.01} value={this.state.scale} onChange={this.setScale} className="ml-1 mr-1" />
                <Button onClick={this.increaseScaling} style={btnStyles}>+</Button>
              </div>
              <Canvas scale={this.state.scale} />
            </div>
            <div className="col-3 bg-white" style={{ minHeight: 500 }}>
              <Editor />
            </div>
          </div>
          <div className="bg-white mt-3">
            {this.renderSaveButton()}
          </div>
        </CardBody>
      </Card>
    );
  }

  render () {
    return this.props.isNew ? <InitialSettings completeInitialSetup={this.completeInitialSetup} /> : this.renderDesigner();
  }
}

function mapStateToProps (state) {
  return {
    loading: state.designer.loading,
    isNew: state.designer.isNew,
    cardInfo: state.designer.cardInfo,
    lastSaved: state.designer.lastSaved
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    saveLayout,
    initialSetup
  }, dispatch);
}

Designer.propTypes = {
  saveLayout: PropTypes.func,
  loading: PropTypes.bool,
  initialSetup: PropTypes.func,
  isNew: PropTypes.bool,
  cardInfo: PropTypes.shape(),
  lastSaved: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(Designer);
