import React from 'react';
import PropTypes from 'prop-types';

import {
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Input
} from 'reactstrap';
import { I18n } from 'utils';
import Dropzone from 'react-dropzone';
import '../componentsStyles.css';

class FixedFieldsModal extends React.Component {
  state = {
    fieldText: '',
    showTextFieldSettings: true,
    photo: false,
    photoSizeError: false
  }
  
  toggleTextFieldSettings = () => {
    this.setState({
      showTextFieldSettings: !this.state.showTextFieldSettings,
      photoSizeError: false,
      photo: false
    });
  }
  
  setFieldText = (e) => {
    this.setState({fieldText: e.target.value});
  }
  
  addField = () => {
    if (this.state.photo) {
      this.props.addField(this.state.fieldText, this.state.photo);
    } else {
      this.props.addField(this.state.fieldText);
    }
    this.setState({
      fieldText: '',
      photo: false
    });
  }
  
  onDrop = (files) => {
    const maxSizeJpeg = 2097152; // 2Mb
    const maxSizePng = 10485760; // 10Mb
    let photo = files[0];
    if (
      (photo.type === 'image/jpeg' && photo.size < maxSizeJpeg) ||
      (photo.type === 'image/png' && photo.size < maxSizePng)
    ) {
      this.setState({ photoSizeError: false });
      let img = new window.Image();
      img.src = photo.preview;
      
      img.onload = () => {
        photo.width = img.width;
        photo.height = img.height;
        this.setState({photo});
      };
    } else {
      const photoSizeError = I18n.t('photo_upload.errors.size_validator');
      this.setState({
        photoSizeError,
        photo: false
      });
    }
  }
  
  toggleModal = () => {
    this.setState({
      photo: false,
      fieldText: ''
    });
    this.props.toggleModal();
  }
  
  render () {
    const active = this.state.showTextFieldSettings;
    return (
      <Modal isOpen={this.props.modalOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal}>{I18n.t('card_layout.fixed_fields.title')}</ModalHeader>
        <ModalBody>
          <div className="info-box">
            {I18n.t('card_layout.fixed_fields.info')}
          </div>
          <Label>{I18n.t('card_layout.fixed_fields.select_field')}</Label>
          <div className="bg-white">
            <ListGroup>
              <ListGroupItem
                color={active ? 'primary' : 'link'}
                className="mb-1 d-flex"
                onClick={this.toggleTextFieldSettings}
                active={active}
                style={{ cursor: 'pointer' }}
              >
                {I18n.t('card_layout.fixed_fields.text_field')}
                {active && <i className="fa fa-check ml-auto mt-1" />}
              </ListGroupItem>
              <ListGroupItem
                color={!active ? 'primary' : 'link'}
                className="mb-1 d-flex"
                onClick={this.toggleTextFieldSettings}
                active={!active}
                style={{ cursor: 'pointer' }}
              >
                {I18n.t('order_item.photo')}
                {!active && <i className="fa fa-check ml-auto" />}
              </ListGroupItem>
            </ListGroup>
          </div>
          <Collapse isOpen={this.state.showTextFieldSettings}>
            <label style={{marginTop: '1em'}}>{I18n.t('card_layout.fixed_fields.add_text')}</label>
            <Input value={this.state.fieldText} onChange={e => this.setFieldText(e)} />
          </Collapse>
          <Collapse isOpen={!this.state.showTextFieldSettings}>
            <div style={{marginLeft: '3em', marginRight: '3em'}}>
              <label style={{marginTop: '1em'}}>{I18n.t('activerecord.attributes.customer.import_column_photo')}</label>
              <Input value={this.state.fieldText} onChange={e => this.setFieldText(e)} />
            </div>
            <Dropzone
              className="designer-dropzone mr-auto ml-auto d-flex flex-column align-items-center justify-content-center"
              accept="image/jpeg, image/png"
              style={{height: '200px', marginTop: '1em'}}
              onDrop={this.onDrop}
            >
              {this.state.photo ?
                <img style={{maxHeight: '190px'}} className="img-fluid" src={this.state.photo.preview} /> :
                <span style={{textAlign: 'center'}}>
                  <i className="fa fa-cloud-upload fa-2x" />
                  <p style={{marginTop: '0.5em'}}>{I18n.t('general.action.dropzone_photo')}</p>
                </span>
              }
            </Dropzone>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          {this.state.photoSizeError || (!this.state.showTextFieldSettings && !this.state.photo) ?
            this.state.photoSizeError :
            <div>
              <Button color="secondary" style={{marginRight: '1em'}} onClick={this.toggleModal}>{I18n.t('action.cancel')}</Button>
              <Button color="primary"onClick={() => this.addField()}>{I18n.t('card_layout.fixed_fields.add_field')}</Button>
            </div>
          }
        </ModalFooter>
      </Modal>
    );
  }
}

FixedFieldsModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  addField: PropTypes.func.isRequired
};

export default FixedFieldsModal;
