import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import store from './store';
import Reports from './reports';

import 'animate.css/animate.min.css';

class App extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <Reports
          orderRequests={this.props.orderRequests}
          systemReports={this.props.systemReports}
          parentReports={this.props.parentReports}
          baseUrl={this.props.baseUrl}
        />
      </Provider>
    );
  }
}

App.propTypes = {
  orderRequests: PropTypes.bool,
  systemReports: PropTypes.bool,
  parentReports: PropTypes.bool,
  baseUrl: PropTypes.string
};

export default App;
