import request from '../../order_item/request';
import req from 'then-request';

export const UPLOAD_BACKGROUND_REQUEST = 'UPLOAD_BACKGROUND_REQUEST';
export const UPLOAD_BACKGROUND_SUCCESS = 'UPLOAD_BACKGROUND_SUCCESS';
export const UPLOAD_BACKGROUND_FAIL = 'UPLOAD_BACKGROUND_FAIL';

export function uploadBackground (cardSide, image, imageName, layoutId) {
  return (dispatch, getState) => {
    dispatch({
      type: UPLOAD_BACKGROUND_REQUEST,
      loading: true
    });

    const url = '/api/card_layouts/' + layoutId + '/background_image';

    let data = {
      cardSide: cardSide,
      background: image,
      filename: imageName
    };

    request.post(url, data)
      .then((resp) => {
        if (resp.statusCode === 200) {
          dispatch({
            type: UPLOAD_BACKGROUND_SUCCESS,
            cardSide,
            payload: resp.parsedBody,
            loading: false
          });
        } else {
          dispatch({
            type: UPLOAD_BACKGROUND_FAIL,
            cardSide,
            payload: resp.parsedBody,
            loading: false
          });
        }
      });
  };
}
