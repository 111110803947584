import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'utils';

const OrderTable = ({ totals, orders }) => (
  <table className="table table-hover">
    <thead>
      <tr>
        <th>{I18n.t('activerecord.attributes.order.confirmed_at')}</th>
        <th>{I18n.t('activerecord.attributes.order.number')}</th>
        <th>{I18n.t('activerecord.attributes.order.status')}</th>
        {Object.keys(totals.cards).map((card) => (
          <th key={card} className="text-center">{card}</th>
        ))}
        <th className="text-center">{I18n.t('order.stats.total')}</th>
      </tr>
    </thead>
    <tbody>
      {orders.map((order) => (
        <tr id={order.id} key={order.id}>
          <td>{order.confirmed_at}</td>
          <td>{order.imola_id}</td>
          <td>{order.status}</td>
          {Object.keys(totals.cards).map((card) => (
            <td key={card} className="text-center">{order.order_stats[card]}</td>
          ))}
          <td className="text-center">
            {Object.values(order.order_stats).reduce((acc, currentVal) => (
              acc + currentVal
            ), 0)}
          </td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      {orders.length > 20 && (
        <tr>
          <th colSpan="3" />
          {Object.keys(totals.cards).map((card) => (
            <th key={card} className="text-center">{card}</th>
          ))}
          <th />
        </tr>
      )}
      <tr>
        <th>{I18n.t('order.stats.total')}</th>
        <td colSpan="2" />
        {Object.keys(totals.cards).map((card) => (
          <td key={card} className="text-center">
            {totals.cards[card]}
          </td>
        ))}
        <td className="text-center">
          {Object.values(totals.cards).reduce((acc, currentVal) => (
            acc + currentVal
          ), 0)}
        </td>
      </tr>
    </tfoot>
  </table>
);

OrderTable.propTypes = {
  orders: PropTypes.array.isRequired,
  totals: PropTypes.shape().isRequired
};

export default OrderTable;
