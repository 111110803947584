import { UPDATE_SELECTED_SIDES } from '../actions/editor';

const initialState = {
  availableFields: {}
};

export default function (state = initialState, action) {
  state = Object.assign({}, initialState, state);

  switch (action.type) {
    case UPDATE_SELECTED_SIDES:
      return Object.assign({}, state, {
        selectedSides: action.selectedSides
      });
    default:
      return state;
  }
}
