import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { I18n } from 'utils';
import './componentsStyles.css';

export const dropzone = (props) => {
  return (
    <Dropzone
      className="designer-dropzone mr-auto ml-auto d-flex flex-column align-items-center justify-content-center"
      onDrop={props.onDrop}
      accept="image/jpeg, image/png"
    >
      <div>
        <i className="fa fa-cloud-upload fa-2x" />
      </div>
      <div>
        {I18n.t('card_layout.action.upload_background')}
      </div>
    </Dropzone>
  );
};

export default dropzone;

dropzone.propTypes = {
  onDrop: PropTypes.func
};
