export const UPDATE_FIELD_COORDINATES = 'UPDATE_FIELD_COORDINATES';
export const UPDATE_FIELD_ATTRIBUTE = 'UPDATE_FIELD_ATTRIBUTE';

export function updateCoordinates (cardSide, fieldId, x, y, width, height) {
  return {
    type: UPDATE_FIELD_COORDINATES,
    cardSide: cardSide,
    fieldId: fieldId,
    x: Number(parseFloat(x).toFixed(2)),
    y: Number(parseFloat(y).toFixed(2)),
    width: width,
    height: height
  };
}

export function updateFieldAttribute (cardSide, fieldId, attr, value) {
  return {
    type: UPDATE_FIELD_ATTRIBUTE,
    cardSide: cardSide,
    fieldId: fieldId,
    attribute: attr,
    value: value
  };
}
