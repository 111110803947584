import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';

import { I18n } from 'utils';

class CoordinateSettings extends React.Component {
  state = {
    maxY: Number(this.props.canvas[this.props.designer.activeCardSide].background.height - this.props.field.height),
    maxX: Number(this.props.canvas[this.props.designer.activeCardSide].background.width - this.props.field.width)
  }

  handleOnChange = (e) => {
    let value = Number(e.target.value);
    if (e.target.name === 'x' && this.state.maxX < value) {
      value = parseFloat(this.state.maxX).toFixed(0) - 1;
    } else if (e.target.name === 'y' && this.state.maxY < value) {
      value = parseFloat(this.state.maxY).toFixed(0) - 1;
    }
    this.props.updateFieldAttribute(e.target.name, value);
  }

  render () {
    return (
      <div className="d-flex col-6">
        <div className="w-100 mr-2">
          <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.x_crd')}</small>
          <Input value={this.props.field.x} name="x" min={0} max={this.state.maxX} type="number" step="1" onChange={this.handleOnChange} />
        </div>
        <div className="w-100">
          <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.y_crd')}</small>
          <Input value={this.props.field.y} name="y" min={0} max={this.state.maxY} type="number" step="1" onChange={this.handleOnChange} />
        </div>
      </div>
    );
  }
}

CoordinateSettings.propTypes = {
  updateFieldAttribute: PropTypes.func,
  field: PropTypes.object,
  canvas: PropTypes.object,
  designer: PropTypes.object
};

function mapStateToProps (state) {
  return {
    canvas: state.canvas,
    designer: state.designer
  };
}

export default connect(mapStateToProps)(CoordinateSettings);