import React from 'react';
import { Transformer } from 'react-konva';

class TransformerComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      fieldType: null,
      anchors: null
    };
  }

  componentDidMount () {
    this.checkNode(this.props);

    if (!['code_128', 'code_39', 'photo'].includes(this.state.fieldType)) {
      this.transformer.on('transform', () => {
        let selectedNode = this.selectedNode();

        if (selectedNode) {
          selectedNode.width(selectedNode.width() * selectedNode.scaleX());
          selectedNode.scaleX(1);
        }
      });
    }
  }

  selectedNode (props = this.props) {
    return this.transformer.getStage().findOne('.' + props.selectedShapeName);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.checkNode(nextProps);
  }

  // here we need to manually attach or detach Transformer node
  checkNode (props) {
    let selectedNode = this.selectedNode(props);
    // do nothing if selected node is already attached
    if (selectedNode === this.transformer.node()) {
      return;
    }

    if (selectedNode) {
      this.setState({
        fieldType: selectedNode.attrs.fieldType,
        anchors: this.anchors(selectedNode.attrs.fieldType)
      });
      // attach to another node
      this.transformer.attachTo(selectedNode);
    } else {
      // remove transformer
      this.transformer.detach();
    }

    this.transformer.getLayer().draw();
  }
  
  anchors = (fieldType) => {
    switch (fieldType) {
      case 'photo':
      case 'code_qr':
        return ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
      case 'code_39':
      case 'code_39e':
      case 'code_128':
        return ['top-center', 'bottom-center', 'middle-right', 'middle-left'];
      default:
        return ['middle-left', 'middle-right'];
    }
  }

  render () {
    return (
      <Transformer
        ref={(node) => { this.transformer = node; }}
        rotateEnabled={false}
        enabledAnchors={this.state.anchors}
        keepRatio />
    );
  }
}

export default TransformerComponent;
