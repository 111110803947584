import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/select';

import { I18n } from 'utils';

class FontSize extends React.Component {
  constructor (props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange ({ value, _label }) {
    this.props.updateFieldAttribute('fontSize', value);
  }

  formatOptions () {
    let result = [];

    for (let i = 14; i < 100; i++) {
      if (i % 2 === 0) {
        result.push({ value: i, label: i.toString() });
      }
    }

    return result;
  }
  
  render () {
    const options = this.formatOptions();
    return (
      <div className="mr-2 mb-0 form-group" style={{ fontSize: '0.875rem', width: 90 }}>
        <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.size')}</small>
        <Select
          options={options}
          value={options.filter((option) => option.value === this.props.value)}
          onChange={this.handleOnChange}
          isClearable={false}
          isSearchable={false}
          autoFocus
          menuContainerStyle={{ zIndex: 1000 }}
        />
      </div>
    );
  }
}

FontSize.propTypes = {
  updateFieldAttribute: PropTypes.func,
  value: PropTypes.number
};

export default FontSize;
