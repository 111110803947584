import request from '../../order_item/request';

export const ACTIVATE_FIELD = 'ACTIVATE_FIELD';
export const LAYOUT_POST = 'LAYOUT_POST';
export const LAYOUT_POST_SUCCESS = 'LAYOUT_POST_SUCCESS';
export const LAYOUT_POST_FAILED = 'LAYOUT_POST_FAILED';
export const CARD_SIDE_CHANGE = 'CARD_SIDE_CHANGE';
export const INITIAL_SETUP_COMPLETE = 'INITIAL_SETUP_COMPLETE';

export function activateField (fieldId) {
  return {
    type: ACTIVATE_FIELD,
    fieldId: fieldId
  };
}

export function changeCardSideTo (cardSide) {
  return {
    type: CARD_SIDE_CHANGE,
    cardSide
  };
}

export function initialSetup (aspectRatioX, aspectRatioY) {
  return {
    type: INITIAL_SETUP_COMPLETE,
    aspectRatioX,
    aspectRatioY
  };
}

export function saveLayout () {
  return (dispatch, getState) => {
    dispatch({
      type: LAYOUT_POST,
      loading: true
    });

    const state = Object.assign({}, getState());
    const data = { card_layout: layoutData(state) };
    const url = '/api/card_layouts/' + state.designer.layoutId;
    
    request.put(url, data)
      .then((resp) => {
        if (resp.statusCode === 200) {
          dispatch({
            type: LAYOUT_POST_SUCCESS,
            loading: false,
            payload: resp.parsedBody
          });
        } else {
          dispatch({
            type: LAYOUT_POST_FAILED,
            loading: false,
            payload: resp.parsedBody
          });
        }
      });
  };
}

function layoutData (state) {
  return {
    front_fields: state.canvas.frontSide.fields,
    front_uv_fields: state.canvas.frontUvSide.fields,
    back_fields: state.canvas.backSide.fields,
    back_uv_fields: state.canvas.backUvSide.fields,
    options: {
      aspect_ratio_x: state.designer.cardInfo.aspectRatioX,
      aspect_ratio_y: state.designer.cardInfo.aspectRatioY,
      card_sides: state.editor.selectedSides
    }
  };
}
