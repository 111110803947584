import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'utils';
import { PieChart } from 'react-chartkick';
import { datalabelOptions } from '../utils';
import { connect } from 'react-redux';
import request from 'then-request'
import { updateLoaderStatus } from '../reducers/loader';;

class GeneralInfo extends React.Component {
  state = {
  }
  
  componentDidMount = () => {
    if (this.props.active) {
      this.fetchData();
    }
  }

  componentDidUpdate = (prevProps) => {
    let sourceChanged = prevProps.sourceWithUrlParams !== this.props.sourceWithUrlParams;
    if ((!prevProps.active && this.props.active) || (this.props.active && sourceChanged)) {
      this.fetchData();
    }
  }
  
  fetchData = () => {
    request('GET', this.props.sourceWithUrlParams).done((res) => {
      const data = JSON.parse(res.getBody());
      this.props.updateLoaderStatus('LOADER_STATUS', false);
      this.setState(data);
    });
  }

render () {
    return (
      <div className="mt-4">
        <table className="table table-hover table-borderless">
          <thead >
            <tr>
              <td />
              <td className="text-center">24h</td>
              <td className="text-center">{I18n.t('reports.general.month')}</td>
              <td className="text-center">{I18n.t('reports.general.total')}</td>
            </tr>
          </thead>
          <tbody>
            <tr style={{borderTop: '1px solid #e9ecef'}}>
              <td>{I18n.t('reports.general.active_users')}</td>
              <td className="text-center">{this.state.activeUsersLately}</td>
              <td className="text-center">{this.state.activeUsersMonth}</td>
              <td className="text-center">{this.state.activeUsers}</td>
            </tr>
            <tr style={{borderTop: '1px solid #e9ecef'}}>
              <td>
                {I18n.t('reports.general.send_orders')}
                <span className="badge badge-pill badge-gray-300 order-status-badge ml-1" style={{marginTop: '0.2rem'}}> {I18n.t('order.status.confirmed')}</span>
              </td>
              <td className="text-center">{this.state.sendOrdersLately}</td>
              <td className="text-center">{this.state.sendOrdersMonth}</td>
              <td className="text-center">{this.state.sendOrders}</td>
            </tr>
            <tr style={{borderTop: '1px solid #e9ecef'}}>
              <td>
                {I18n.t('reports.general.send_order_items')}
                <span className="badge badge-pill badge-gray-300 order-status-badge ml-1" style={{marginTop: '0.2rem'}}> {I18n.t('order.status.confirmed')}</span>
              </td>
              <td className="text-center">{this.state.orderItemsInSendOrdersLately}</td>
              <td className="text-center">{this.state.orderItemsInSendOrdersMonth}</td>
              <td className="text-center">{this.state.orderItemsInSendOrders}</td>
            </tr>
            <tr style={{borderTop: '1px solid #e9ecef'}}>
              <td>{I18n.t('reports.general.unconfirmed_orders')}</td>
              <td className="text-center">{this.state.unconfirmeOrdersLately}</td>
              <td className="text-center">{this.state.unconfirmeOrdersMonth}</td>
              <td className="text-center">{this.state.unconfirmeOrders}</td>
            </tr>
          </tbody>
        </table>
        <div className="row d-flex align-items-center ml-2">
          <PieChart
            data={this.state.ordersByStatus}
            id="order-items-total"
            library={{ animation: { duration: 1000 }, ...datalabelOptions }}
            download={{ background: '#ffff' }}
            title={I18n.t('reports.general.status_month')}
          />
        </div>
      </div>
    )
  }
}

GeneralInfo.propTypes = {
  sourceWithUrlParams: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  updateLoaderStatus: PropTypes.func
};

export default connect(null, { updateLoaderStatus })(GeneralInfo);