import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { I18n } from 'utils';

class SideSelector extends React.Component {
  styles = () => {
    return {
      'paddingLeft': '1rem',
      'paddingRight': '1rem',
      'cursor': 'pointer'
    };
  };

  renderItems = () => {
    let list = this.props.selectedSides.map((side) => {
      return { key: side, translation: I18n.t('card_layout.editor.' + side.replace('Side', '_side')) };
    }).sort((a, b) => { return a.translation.localeCompare(b.translation); });

    return list.map((side) => {
      let active = this.props.activeCardSide === side.key;
      return (
        <ListGroupItem
          color={active ? 'primary' : 'link'}
          key={side.key}
          className={classNames('d-flex', 'align-items-center', {'font-weight-bold': active})}
          onClick={() => this.props.onCardSideClick(side.key)}
          active={active}
          style={this.styles()}
        >
          {side.translation}
          {active && <i className="fa fa-eye ml-auto" />}
        </ListGroupItem>
      );
    });
  }
  
  render () {
    return (
      <ListGroup className="d-flex flex-column">
        {this.renderItems()}
      </ListGroup>
    );
  }
}

SideSelector.propTypes = {
  activeCardSide: PropTypes.string.isRequired,
  onCardSideClick: PropTypes.func.isRequired,
  selectedSides: PropTypes.array.isRequired
};

export default SideSelector;
