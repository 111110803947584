import React from 'react';
import ReactSelect from 'react-select';

// Simple wrapper for react-select to add theme
const Select = (props) => (
  <ReactSelect
    theme={(theme) => ({
      ...theme,
      borderRadius: 0
    })}
    {...props}
  />
);

export default Select;
