import reducers from '../reducers';
import thunkMiddleware from 'redux-thunk'; // For handling async actionCreators.
import { createStore, compose, applyMiddleware } from 'redux';

function store (initialState) {
  return createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      window.devToolsExtension ? window.devToolsExtension() : f => f // Add redux devtools
    )
  );
}

export default store;
