import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/select';

import { I18n } from 'utils';

class FontFamily extends React.Component {
  constructor (props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange ({ value, _label }) {
    this.props.updateFieldAttribute('fontFamily', value);
  }

  formatOptions () {
    return this.props.fonts.map((font) => {
      return { value: font, label: font };
    });
  }

  render () {
    const options = this.formatOptions();
    return (
      <div
        className="mr-2 mb-0 form-group"
        style={{ width: '180px', fontSize: '0.875rem' }}
      >
        <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.font')}</small>
        <Select
          options={options}
          value={options.filter((option) => option.value === this.props.value)}
          onChange={this.handleOnChange}
          isClearable={false}
          isSearchable={false}
        />
      </div>
    );
  }
}

FontFamily.propTypes = {
  updateFieldAttribute: PropTypes.func,
  value: PropTypes.string,
  fonts: PropTypes.array
};

export default FontFamily;
