import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ColumnChart, PieChart} from 'react-chartkick';
import 'chart.js';
import request from 'then-request';
import { updateLoaderStatus } from '../reducers/loader';

import { I18n } from 'utils';
import { datalabelOptions } from '../utils';
import OrderTable from './order-table';
import CustomerOrdersTable from './customer-orders-table';

class TotalOrders extends React.Component {
  state = {
    chartData: [],
    orders: [],
    customersData: [],
    pieData: {},
    totals: {
      orders: 0
    }
  }

  componentDidMount = () => {
    if (this.props.active) {
      this.fetchData();
    }
  }

  componentDidUpdate = (prevProps) => {
    let sourceChanged = prevProps.sourceWithUrlParams !== this.props.sourceWithUrlParams;
    if ((!prevProps.active && this.props.active) || (this.props.active && sourceChanged)) {
      this.fetchData();
    }
  }

  fetchData = () => {
    request('GET', this.props.sourceWithUrlParams).done((res) => {
      const data = JSON.parse(res.getBody());
      let { chartData, orders, totalCount, customersData, periodOrders } = data;
      let totalCards = {};

      if (orders) {
        orders.forEach((order) => {
          let stats = order.order_stats;
          Object.keys(stats).forEach((card) => {
            totalCards[card] = totalCards[card] || 0;
            totalCards[card] += stats[card];
          });
        });
      }

      let totals = {
        orders: totalCount,
        cards: totalCards,
        cardNames: Object.keys(totalCards)
      };
      if (this.props.systemWide) {
        chartData = JSON.parse(chartData);
      }
      let pieData = {};
      if (chartData.length > 0) {
        if (chartData.length > 10) {
          chartData = chartData.sort((a, b) => this.customerOrdersCount(a.data) > this.customerOrdersCount(b.data) ? 1 : this.customerOrdersCount(b.data) > this.customerOrdersCount(a.data)).slice(-7)
        }
        chartData.forEach((array) => {
          let subTotal = this.customerOrdersCount(array.data)
          array.name += ` (${subTotal})`;
          pieData[array.name] = subTotal;
        });
      }
      this.props.updateLoaderStatus('LOADER_STATUS', false);
      this.setState({ chartData, pieData, periodOrders, orders, totals, customersData });
    });
  }
  
  customerOrdersCount = (customerData) => {
    return Object.values(customerData).reduce((acc, val) => (acc + val[1]), 0)
  }

  render () {
    const { totals, orders, chartData, customersData, periodOrders } = this.state;
    return (
      <div>
        {!!chartData && (
          <div className="mb-3">
            <div className="row d-flex align-items-center ml-2 mb-3">
              <ColumnChart
                data={this.props.systemWide ? periodOrders : chartData}
                id="orders-total"
                library={{ animation: { duration: 1000 }, ...datalabelOptions }}
                download={{ background: '#ffff' }}
                title={`${this.props.title} (${totals.orders})`}
              />
            </div>
            {this.props.systemWide &&
              <div>
                <div className="row d-flex align-items-center ml-2">
                  <PieChart
                    data={this.state.pieData}
                    id="order-items-total"
                    library={{ animation: { duration: 1000 }, ...datalabelOptions }}
                    download={{ background: '#ffff' }}
                    title={I18n.t('reports.chart.title.customers')}
                  />
                </div>
              </div>
            }
          </div>
        )}
        {this.props.systemWide ?
          <CustomerOrdersTable customerOrders={customersData} totals={totals} />
          :
          orders && orders.length > 0 && <OrderTable orders={orders} totals={totals} />
        }
      </div>
    );
  }
}

TotalOrders.propTypes = {
  title: PropTypes.string.isRequired,
  sourceWithUrlParams: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  systemWide: PropTypes.bool,
  statusPie: PropTypes.bool,
  updateLoaderStatus: PropTypes.func
};

export default connect(null, { updateLoaderStatus })(TotalOrders);
