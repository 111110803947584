import React from 'react';
import PropTypes from 'prop-types';

import AddressInput from './address_input';

class CustomAddress extends React.Component {
  render () {
    let {
      company,
      name,
      street,
      zip,
      city,
      country,
      changeCustomAddressValue,
      customAddressAllowed,
      containerStyles,
      requireCompany
    } = this.props;

    return (
      <div className="addresses-box w-85" style={containerStyles}>
        <div className="row">
          <div className="col-lg-6 form-group">
            <AddressInput
              required={requireCompany}
              name="company"
              value={company}
              changeCustomAddressValue={changeCustomAddressValue}
              disabled={!customAddressAllowed}
              errors={requireCompany && this.props.company_error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 form-group">
            <AddressInput
              required
              name="name"
              value={name}
              changeCustomAddressValue={changeCustomAddressValue}
              disabled={!customAddressAllowed}
              errors={this.props.name_error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-6 form-group">
            <AddressInput
              required
              name="street"
              value={street}
              changeCustomAddressValue={changeCustomAddressValue}
              disabled={!customAddressAllowed}
              errors={this.props.street_error}
            />
          </div>
          <div className="col-sm-4 col-lg-2 form-group">
            <AddressInput
              required
              name="zip"
              value={zip}
              changeCustomAddressValue={changeCustomAddressValue}
              disabled={!customAddressAllowed}
              errors={this.props.zip_error}
            />
          </div>
          <div className="col-sm-8 col-lg-4 form-group">
            <AddressInput
              required
              name="city"
              value={city}
              changeCustomAddressValue={changeCustomAddressValue}
              disabled={!customAddressAllowed}
              errors={this.props.city_error}
            />
          </div>
          <div className="col-sm-8 col-lg-4 form-group">
            <AddressInput
              required
              type="select"
              name="country"
              value={country}
              options={this.props.countryOptions}
              changeCustomAddressValue={changeCustomAddressValue}
              disabled={!customAddressAllowed}
              errors={this.props.country_error}
            />
          </div>
        </div>
      </div>
    );
  }
}

CustomAddress.propTypes = {
  changeCustomAddressValue: PropTypes.func,
  city_error: PropTypes.array,
  city: PropTypes.string,
  company_error: PropTypes.array,
  company: PropTypes.string,
  containerStyles: PropTypes.shape(),
  country_error: PropTypes.array,
  country: PropTypes.string,
  countryOptions: PropTypes.array,
  customAddressAllowed: PropTypes.bool,
  name_error: PropTypes.array,
  name: PropTypes.string,
  requireCompany: PropTypes.bool,
  street_error: PropTypes.array,
  street: PropTypes.string,
  zip_error: PropTypes.array,
  zip: PropTypes.string
};

export default CustomAddress;
