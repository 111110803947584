import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import ErrorMessages from '../fields/error_messages';
import { errorStyles, I18n } from '../../../../utils';

class AddressInput extends React.Component {
  constructor (props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleValueChange (e) {
    let property = e.target.name.replace(/^address_/, '');
    let value = e.target.value;
    this.props.changeCustomAddressValue(property, value);
  }
  
  renderLabel () {
    let { name, required } = this.props;
    let label = I18n.t(`activerecord.attributes.address.${name}`);
    return (
      <label>
        {required ? '* ' : ''}
        {label}
      </label>
    );
  }

  formatOptions () {
    return this.props.options.map(c => ({ label: c[0], value: c[1] }));
  }

  renderInput () {
    let { type, name, value, errors, disabled } = this.props;
    return (
      type === 'select' ?
        <Select
          options={this.formatOptions()}
          value={this.formatOptions().find((option) => option.value === value)}
          onChange={({ value, _label }) => { this.handleValueChange({ target: { value: value, name: 'address_' + name } }); }}
          clearable={false}
          required
        /> :
        <input
          className="form-control field-input"
          type="text"
          onChange={this.handleValueChange}
          value={value}
          name={'address_' + name}
          style={errors ? errorStyles : null}
          disabled={disabled}
        />
    );
  }

  render () {
    return (
      <div>
        {this.renderLabel()}
        {this.renderInput()}
        <ErrorMessages errors={this.props.errors} />
      </div>
    );
  }
}

AddressInput.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  changeCustomAddressValue: PropTypes.func,
  options: PropTypes.array
};

export default AddressInput;
