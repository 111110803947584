import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/select';

import { I18n } from 'utils';

class TextRotate extends React.Component {
  constructor (props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange ({ value, _label }) {
    this.props.updateFieldAttribute('rotation', parseInt(value));
  }

  formatOptions () {
    let options = [];

    for (let i = 0; i < 4; i++) {
      let value = i * 90;
      options.push({ value, label: `${value}°` });
    }
    return options;
  }
  
  render () {
    const options = this.formatOptions();

    return (
      <div className="col-3 form-group">
        <small className="text-uppercase text-muted">{I18n.t('card_layout.toolbar.rotation')}</small>
        <Select
          options={options}
          value={options.filter(({ value }) => value === (this.props.value || 0))}
          onChange={this.handleOnChange}
          isClearable={false}
          isSearchable={false}
          autoFocus
          styles={{
            menu: (provided, state) => ({
              ...provided,
              zIndex: 1000
            })
          }}
        />
      </div>
    );
  }
}

TextRotate.propTypes = {
  updateFieldAttribute: PropTypes.func,
  value: PropTypes.number
};

export default TextRotate;
